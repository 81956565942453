import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import jQuery from 'jquery';

export const NewJournalTableRow = ({
  onChange,
  changeMemo,
  categoryChange,
  changeJob,
  changeName,
  ioTaxTypeChange,
  jobList,
  changeCredit,
  changeDebit,
  default_category_list,
  namelist,
  copyRow,
  deleteRow,
  gst_list,
  idx,
  taxClick,
  row = {
    descr: '',
    credit: '',
    name: '',
    name_text: '',
    name_type: '',
    debit: '',
    foreign_debit: '',
    foreign_credit: '',
    selectedTax: '',
    category: '',
    tax_name: '',
    tax_rate: 0,
    tax_type: '',
    item_tax_debit: '',
    item_tax_credit: '',
    foreign_item_tax_credit: '',
    foreign_item_tax_debit: '',
  },
}) => {
  const [data_table, setDataTable] = useState({
    // descr: '',
    // credit: '',
    // name: '',
    // name_text: '',
    // name_type: '',
    // debit: '',
    // foreign_debit: '',
    // foreign_credit: '',
    // selectedTax: '',
    // category: '',
    // tax_name: '',
    // tax_rate: 0,
    // tax_type: '',
    // item_tax_debit: '',
    // item_tax_credit: '',
    // foreign_item_tax_credit: '',
    // foreign_item_tax_debit: '',
    ...row,
  });
  const [data_table1, setDataTable1] = useState({
    ...row,
  });

  const [disable, setDisable] = useState(false);
  const timeOutRef = useRef();
  const timeOutRef2 = useRef();
  const timeOutRef3 = useRef();
  useEffect(() => {
    setDataTable(row);
  }, [row]);

  const disField = value => {
    const item = namelist.find(item => String(item.id) === String(value));

    const type_name = item?.type_name || null;

    if (type_name === 'Vendor') {
      setDisable(true);
      console.log('Field is disabled');
    } else {
      setDisable(false);
      console.log('Field is enabled');
    }
  };

  return (
    <tr>
      <td>
        <span className="drag-icon">
          <img src="images/dots-menu.svg" alt="icon" />
        </span>
      </td>
      <td className="text-center">{idx + 1}</td>
      <td>
        <textarea
          className="form-control"
          placeholder="Enter Description"
          id={`descr${idx}`}
          name="descr"
          ref={timeOutRef3}
          value={data_table.descr}
          onChange={e => {
            e.persist();
            clearTimeout(timeOutRef3.current);
            const updatedDescr = e.target.value;
            setDataTable(prev => ({ ...prev, descr: updatedDescr }));
            timeOutRef3.current = setTimeout(() => {
              changeMemo(idx, updatedDescr, false);
            }, 1500);
          }}
        />
      </td>
      {/* <td>
             <span className="drag-icon">
      <img src="images/dots-menu.svg" alt="icon" />
    </span>
     <textarea
       className="form-control"
      placeholder="Enter Description"
     id={`descr${idx}`}
       name="descr"
       value={this.state.data_table[idx].descr}
       onChange={e => {
        this.changeMemo(idx, e.target.value, false);
       }}
    />
   </td> */}
      <td>
        <select
          className="selectpicker form-control add-new"
          data-live-search="true"
          title="Choose"
          value={data_table.name}
          onChange={e => {
            e.persist();
            disField(e.target.value);
            if (e.target.value == 'Add') {
              // this.setState({ idx });
              // window.open('/add-new-customer')
              window.jQuery('#add-new-modal').modal('show');
            } else {
              setDataTable(p => ({ ...p, name: e.target.value }));

              changeName(idx, e.target.value);
            }
          }}
        >
          <option value="Add">Add new</option>
          {namelist.map((data, idx) => {
            return (
              <option
                key={idx + 'dd'}
                value={data.id}
                // disabled={data.status == 2 ? true : false}
                style={{ display: data.status == 2 ? 'none' : 'block' }}
              >
                {data.name}
              </option>
            );
          })}
        </select>
      </td>

      {/* <td>
        <select
          className="selectpicker form-control add-new"
          data-live-search="true"
          title="Choose..."
          //  id='selected_job_id'
          //  name='selected_job_id'
          value={data_table.selected_job_id}
          disabled={data_table.name_type !== 1}
          onChange={e => {
            // const { name, value } = e.target;
            // let table_items_array = this.state.item_array;
            //     table_items_array[j]["selected_job_id"] = value;
            // this.setState({
            //   item_array: table_items_array
            // });

            setDataTable(p => ({ ...p, selected_job_id: e.target.value }));

            changeJob(idx, e.target.value);
          }}
        >
          <option value="new">Choose...</option>
          {jobList &&
            jobList.map(val => {
              return (
                <option
                  value={JSON.stringify(val.job_id)}
                  disabled={val.status === 2 ? true : false}
                  style={{
                    display: val.status === 2 ? 'none' : 'block',
                  }}
                >
                  {val.job_name}
                </option>
              );
            })}
        </select>
      </td> */}

      <td>
        <select
          className="selectpicker form-control add-new"
          data-live-search="true"
          title="Choose..."
          value={data_table.selected_job_id}
          disabled={disable}
          onChange={e => {
            const selectedValue = e.target.value;
            setDataTable(prev => ({ ...prev, selected_job_id: selectedValue }));
            changeJob(idx, selectedValue);
            disField(selectedValue);
          }}
        >
          <option value="new">Choose...</option>
          {jobList &&
            jobList.map(val => (
              <option
                key={val.job_id}
                value={val.job_id}
                disabled={val.status === 2}
                style={{
                  display: val.status === 2 ? 'none' : 'block',
                }}
              >
                {val.job_name}
              </option>
            ))}
        </select>
      </td>

      <td>
        <select
          className="selectpicker form-control add-new"
          data-live-search="true"
          title="Choose"
          value={data_table.category}
          // id={`categry_id${itemid}`}
          onChange={e => {
            // this.setState({ idx });
            e.persist();
            if (e.target.value == '1e') {
              jQuery(`#categry_id option`).prop('selected', false).trigger('change');

              window.jQuery('#pop-modal').modal('show');
            } else {
              setDataTable(p => ({ ...p, category: e.target.value }));

              categoryChange(idx, e.target.value);
            }
          }}
        >
          <option value="1e">Create New </option>
          {default_category_list &&
            default_category_list.map(item => {
              return (
                <option
                  value={item.id}
                  data-status={item.id}
                  disabled={item.status == 2 ? true : false}
                  style={{ display: item.status == 2 ? 'none' : 'block' }}
                >
                  {item.name}
                </option>
              );
            })}
        </select>
      </td>

      <td className="text-center ">
        <select
          className="selectpicker form-control add-new"
          data-live-search="true"
          title="Choose"
          value={data_table.selectedTax}
          // id={`categry_id${itemid}`}
          onChange={e => {
            // setTimeout(() => {
            //   this.setState({ idx });
            // }, 500);
            e.persist();
            if (e.target.value == '1e') {
              jQuery(`#categry_id option`).prop('selected', false).trigger('change');

              window.jQuery('#pop-modal-1').modal('show');
            } else {
              setDataTable(p => ({ ...p, selectedTax: e.target.value }));
              // this.taxClick(idx, e.target.value);
              if (taxClick) {
                taxClick(idx, e.target.value); // Safely call taxClick
              } else {
                console.error('taxClick is not defined');
              }
            }
          }}
        >
          <option value="1e">Add New </option>
          {gst_list &&
            gst_list.map(item => {
              return (
                <option value={item.id} data-status={item.id} style={{ display: item.show_on_list == 2 ? 'none' : 'block' }}>
                  {item.sales_tax_name}
                </option>
              );
            })}
        </select>
      </td>

      <td className="text-center ">
        <select
          className="selectpicker form-control add-new"
          data-live-search="true"
          title="Choose"
          value={data_table.io_type_of_tax}
          onChange={e => {
            // setTimeout(() => {
            //   this.setState({ idx });
            // }, 500);
            e.persist();
            setDataTable(p => ({ ...p, io_type_of_tax: e.target.value }));

            //ioTaxTypeChange(idx, e.target.value);
          }}
        >
          <option value="output_tax" data-status="1">
            Output Tax
          </option>
          <option value="input_tax" data-status="2">
            Input Tax
          </option>
        </select>
      </td>

      <td className="text-right">
        <input
          type="text"
          ref={timeOutRef2}
          autoComplete="off"
          className="form-control"
          placeholder="0.00"
          onKeyDown={event => {
            // working fine without decimals
            // if (!/[0-9]/.test(event.key)) {
            // 22-11-2022
            var p = new RegExp(/^[0-9]+([.][0-9]+)?$/);

            if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(event.key)) {
              return;
            }
            // if (!event.key === 0 || p.test(String.fromCharCode(event.key))) {
            //   event.preventDefault();
            // }

            if (!/[0-9.]/.test(event.key) || (event.key === '.' && event.target.value.includes('.'))) {
              event.preventDefault();
            }
          }}
          name="debit"
          value={data_table.foreign_debit}
          onChange={e => {
            e.persist();
            clearTimeout(timeOutRef2.current);
            setDataTable(p => ({ ...p, foreign_debit: e.target.value }));
            setDataTable(p => ({ ...p, foreign_credit: '' }));

            timeOutRef2.current = setTimeout(() => {
              changeDebit(idx, e.target.value);
            }, 1500);
          }}
        />
      </td>
      <td className="text-right">
        <input
          ref={timeOutRef}
          type="text"
          autoComplete="off"
          className="form-control"
          placeholder="0.00"
          onKeyDown={event => {
            // working fine without decimals
            // if (!/[0-9]/.test(event.key)) {
            // 22-11-2022
            var p = new RegExp(/^[0-9]*\.?[0-9]*$/);
            if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(event.key)) {
              return;
            }
            console.log(p.test(String.fromCharCode(event.key)), '-----test---', event.key);
            // if (!event.key === '0' || p.test(String.fromCharCode(event.key))) {
            //   event.preventDefault();
            // }
            if (!/[0-9.]/.test(event.key) || (event.key === '.' && event.target.value.includes('.'))) {
              event.preventDefault();
            }
          }}
          name="credit"
          value={data_table.foreign_credit}
          onChange={e => {
            console.log(e.target, '-----');
            e.persist();
            clearTimeout(timeOutRef.current);
            setDataTable(p => ({ ...p, foreign_credit: e.target.value }));
            setDataTable(p => ({ ...p, foreign_debit: '' }));
            timeOutRef.current = setTimeout(() => {
              changeCredit(idx, e.target.value);
            }, 1500);
          }}
        />
        <div className="action-wrap">
          <a href="javascript:;" className="clone-row" onClick={() => copyRow(idx)}>
            <img src="images/clone-icon.svg" alt="icon" />
          </a>
          <a href="javascript:;" className="del-row" onClick={() => deleteRow(idx)}>
            <img src="images/delete-icon.svg" alt="icon" />
          </a>
        </div>
      </td>
    </tr>
  );
};

// <tr key={idx + 'dkd'}>
//   <td>
//     <span className="drag-icon">
//       <img src="images/dots-menu.svg" alt="icon" />
//     </span>
//     <textarea
//       className="form-control"
//       placeholder="Enter Description"
//       id={`descr${idx}`}
//       name="descr"
//       value={this.state.data_table[idx].descr}
//       onChange={e => {
//         this.changeMemo(idx, e.target.value, false);
//       }}
//     />
//   </td>
//   <td>
//     <select
//       className="selectpicker form-control add-new"
//       data-live-search="true"
//       title="Choose"
//       value={this.state.data_table[idx].name}
//       onChange={e => {
//         if (e.target.value == 'Add') {
//           this.setState({ idx });
//           // window.open('/add-new-customer')
//           window.jQuery('#add-new-modal').modal('show');
//         } else {
//           this.changeName(idx, e.target.value);
//         }
//       }}
//     >
//       <option value="Add">Add new</option>
//       {this.state.namelist.map((data, idx) => {
//         return (
//           <option
//             value={data.id}
//             disabled={data.status == 2 ? true : false}
//             style={{ display: data.status == 2 ? 'none' : 'block' }}
//           >
//             {data.name}
//           </option>
//         );
//       })}
//     </select>
//   </td>

//   <td>
//     <select
//       className="selectpicker form-control add-new"
//       data-live-search="true"
//       title="Choose..."
//       //  id='selected_job_id'
//       //  name='selected_job_id'
//       value={this.state.data_table[idx].selected_job_id}
//       disabled={this.state.data_table[idx].name_type != 1}
//       onChange={e => {
//         // const { name, value } = e.target;
//         // let table_items_array = this.state.item_array;
//         //     table_items_array[j]["selected_job_id"] = value;
//         // this.setState({
//         //   item_array: table_items_array
//         // });
//         this.changeJob(idx, e.target.value);
//       }}
//     >
//       <option value="new">Choose...</option>
//       {this.state.jobList.map(val => {
//         return (
//           <option
//             value={JSON.stringify(val.job_id)}
//             disabled={val.status === 2 ? true : false}
//             style={{
//               display: val.status === 2 ? 'none' : 'block',
//             }}
//           >
//             {val.job_name}
//           </option>
//         );
//       })}
//     </select>
//   </td>

//   <td>
//     <select
//       className="selectpicker form-control add-new"
//       data-live-search="true"
//       title="Choose"
//       value={this.state.data_table[idx].category}
//       // id={`categry_id${itemid}`}
//       onChange={e => {
//         this.setState({ idx });
//         if (e.target.value == '1e') {
//           jQuery(`#categry_id option`).prop('selected', false).trigger('change');

//           window.jQuery('#pop-modal').modal('show');
//         } else {
//           this.categoryChange(idx, e.target.value);
//         }
//       }}
//     >
//       <option value="1e">Create New </option>
//       {this.state.default_category_list &&
//         this.state.default_category_list.map(item => {
//           return (
//             <option
//               value={item.id}
//               data-status={item.id}
//               disabled={item.status == 2 ? true : false}
//               style={{ display: item.status == 2 ? 'none' : 'block' }}
//             >
//               {item.name}
//             </option>
//           );
//         })}
//     </select>
//   </td>

//   <td className="text-center ">
//     <select
//       className="selectpicker form-control add-new"
//       data-live-search="true"
//       title="Choose"
//       value={this.state.data_table[idx].selectedTax}
//       // id={`categry_id${itemid}`}
//       onChange={e => {
//         setTimeout(() => {
//           this.setState({ idx });
//         }, 500);

//         if (e.target.value == '1e') {
//           jQuery(`#categry_id option`).prop('selected', false).trigger('change');

//           window.jQuery('#pop-modal-1').modal('show');
//         } else {
//           this.taxClick(idx, e.target.value);
//         }
//       }}
//     >
//       <option value="1e">Add New </option>
//       {this.state.gst_list.map(item => {
//         return (
//           <option
//             value={item.id}
//             data-status={item.id}
//             style={{ display: item.show_on_list == 2 ? 'none' : 'block' }}
//           >
//             {item.sales_tax_name}
//           </option>
//         );
//       })}
//     </select>
//   </td>

//   <td className="text-center ">
//     <select
//       className="selectpicker form-control add-new"
//       data-live-search="true"
//       title="Choose"
//       value={this.state.data_table[idx].io_type_of_tax}
//       onChange={e => {
//         setTimeout(() => {
//           this.setState({ idx });
//         }, 500);

//         this.ioTaxTypeChange(idx, e.target.value);
//       }}
//     >
//       <option value="output_tax" data-status="1">
//         Output Tax
//       </option>
//       <option value="input_tax" data-status="2">
//         Input Tax
//       </option>
//     </select>
//   </td>

//   <td className="text-right">
//     <input
//       type="text"
//       autoComplete="off"
//       className="form-control"
//       placeholder="0.00"
//       onKeyDown={event => {
//         // working fine without decimals
//         // if (!/[0-9]/.test(event.key)) {
//         // 22-11-2022
//         var p = new RegExp(/^[0-9]+([.][0-9]+)?$/);
//         if (!event.key === 0 || p.test(String.fromCharCode(event.key))) {
//           event.preventDefault();
//         }
//       }}
//       name="debit"
//       value={this.state.data_table[idx].foreign_debit}
//       onChange={e => {
//         this.changeDebit(idx, e.target.value);
//       }}
//     />
//   </td>
//   <td className="text-right">
//     <input
//       type="text"
//       autoComplete="off"
//       className="form-control"
//       placeholder="0.00"
//       onKeyPress={event => {
//         // working fine without decimals
//         // if (!/[0-9]/.test(event.key)) {
//         // 22-11-2022
//         var p = new RegExp(/^[0-9]+([.][0-9]+)?$/);
//         if (!event.key === 0 || p.test(String.fromCharCode(event.key))) {
//           event.preventDefault();
//         }
//       }}
//       name="credit"
//       value={this.state.data_table[idx].foreign_credit}
//       onChange={e => {
//         this.changeCredit(idx, e.target.value);
//       }}
//     />
//     <div className="action-wrap">
//       <a href="javascript:;" className="clone-row" onClick={() => this.copyRow(idx)}>
//         <img src="images/clone-icon.svg" alt="icon" />
//       </a>
//       <a href="javascript:;" className="del-row" onClick={() => this.deleteRow(idx)}>
//         <img src="images/delete-icon.svg" alt="icon" />
//       </a>
//     </div>
//   </td>
// </tr>
