import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { ModuleRegistry, ClientSideRowModelModule } from 'ag-grid-community';
import { ExcelExportModule, LicenseManager } from 'ag-grid-enterprise';

const initialState = {
  geminiKeys: null,
};
const userdetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_LOGGED_USER_DETAILS':
      return { ...state, ...action.payload };
    case 'CLEAR_LOGGED_USER_DETAILS':
      return {};
    case 'SET_GEMINI_KEYS':
      return { ...state, geminiKeys: action.payload };
    default:
      return state;
  }
};

ModuleRegistry.registerModules([ExcelExportModule, ClientSideRowModelModule]);

const store = createStore(userdetailReducer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
