import React from 'react';
import UserTopbar from '../components/first_user_module/header';
import FetchAllApi from '../api_links/fetch_all_api';
import Sidebar from './preferenceSide';
import jQuery from 'jquery';
import Footer from '../components/footer.jsx';
import Category from './categoryadd';
import './preference.css';
import Comma from './../components/comma';
import { PDFtoIMG } from 'react-pdf-to-image';

import config from './../api_links/api_links';
import moment from 'moment';
import Swal from 'sweetalert2';
import { NewJournalTableRow } from '../components/common/new_journal_table_row.jsx';

export default class Newjournal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),
      lock_date: localStorage.getItem('lock_date'),
      narration: '',
      entry_no: '',
      date: '',
      auto_reversing_date: '',
      data_table: [
        {
          descr: '',
          credit: '',
          name: '',
          name_text: '',
          name_type: '',
          debit: '',
          foreign_debit: '',
          foreign_credit: '',
          selectedTax: '',
          category: '',
          tax_name: '',
          tax_rate: 0,
          tax_type: '',
          item_tax_debit: '',
          item_tax_credit: '',
          foreign_item_tax_credit: '',
          foreign_item_tax_debit: '',
        },
      ],
      myarray: [],
      rows: ['row 1'],
      initial_value: 0,
      isTablefilled: false,
      taxName: '',
      taxType: '',
      taxRate: '',
      taxCode: '',
      deleteArr: [],
      show_succes: false,
      country_code: '',
      modal_info_msg: '',
      selected_rate_type: '%',
      maximum_chr_lngth: 4,
      selectedOption: 'option2',
      rate_entered: '',
      search_key_gst: '',
      gst_list: [],
      isAdd: false,
      tax: '',
      account: '',
      new_name: '',
      radio: '',
      including_tax: false,
      addRate: '',
      debitSubTotal: '00.00',
      creditSubTotal: '00.00',
      debitSubTotalHome: '00.00',
      creditSubTotalHome: '00.00',
      taxTotalDebit: 0,
      taxTotalDebitHome: 0,
      taxTotalCredit: 0,
      taxTotalCreditHome: 0,
      exchange_rate: '',
      clientHomeCurrency: '',
      //currency_list: [],
      currency_list: config.all_currency_list,
      currency: {},
      default_category_list: [],
      selected_currency: '',
      exchange_rate: '',
      totalTaxarrDebit: [],
      totalTaxarrCredit: [],
      indexdebit: 0,
      indexcredit: 0,
      grandTotalDebit: '00.00',
      grandTotalDebitHome: '00.00',
      grandTotalCredit: '00.00',
      grandTotalCreditHome: '00.00',
      debitSubTotalarr: [],
      creditSubTotalarr: [],
      taxincluarrDebit: [],
      taxincluarrCredit: [],
      indetax: 0,
      namelist: [],
      errorMsg: false,
      tableerror: false,
      check: true,
      customer: localStorage.getItem('customerId') || localStorage.getItem('third_party_customer_id'),
      loading: false,
      isEdit: false,
      journal_id: '',
      old_password: '',
      savestatus: '',

      selectedFile: '',
      succ_msg: '',
      attachment_file: [],
      attachment_file_length: 0,
      attachment_fileName: [],
      imgThumb: '',
      pages: [],
      list_of_attachments: [],
      is_invalid_subject: false,
      is_invalid_message: false,
      is_files_not_selected: false,
      is_home_currency_adjustment: false,
      old_transaction_date: '',
      sortableOrder: null,
      message_to_display: 'This date is beyond lock date. To apply this date , need to verify Lock Date password',
      jobList: [],
    };
    this.taxindeb = [];
    this.subtdeb = [];
    this.taxincre = [];
    this.subtcre = [];
    this.loadFile = this.loadFile.bind(this);
    this.sortableRef = React.createRef();
  }

  removeImage = (e, index) => {
    const updated = this.state.attachment_file.filter(item => item.name !== e);
    this.setState(
      {
        attachment_file: updated,
      },
      () => {
        if (this.state.attachment_file.length === 0) {
          // Reset file input value when no files are selected
          document.getElementById('imgInp').value = '';
          // jQuery('.upload-space').removeClass('hide')
          jQuery('.upload-space').css({
            opacity: 1,
          });
          jQuery('.upload-thumb').addClass('hide');
        }
      },
    );
  };

  loadFile(e) {
    var files = e.target.files;
    this.setState({ attachment_file_length: files.length });
    console.log(files, 'res prev');
    if (files.length > 0) {
      // jQuery('.upload-space').addClass('hide')
      jQuery('.upload-space').css({
        opacity: 0,
      });
      jQuery('.upload-thumb').removeClass('hide');
      var fileArra = this.state.attachment_file;
      //var fileThumbArra = this.state.imgThumb;
      for (var i = 0; i < files.length; i++) {
        // let fname = e.target.files[i].name.toLowerCase();
        let fname = files[i].name.toLowerCase();
        if (
          fname.endsWith('.pdf') ||
          fname.endsWith('.png') ||
          fname.endsWith('.jpg') ||
          fname.endsWith('.jpeg') ||
          fname.endsWith('.xls') ||
          fname.endsWith('.xlsx')
        ) {
          // let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() == e.target.files[i].name.toLowerCase());
          let previous_attachments = this.state.attachment_file.filter(item => item.name.toLowerCase() === fname);
          console.log(this.state.attachment_file, previous_attachments, 'res prev', fname);

          if (previous_attachments.length === 0) {
            // fileArra.push(e.target.files[i]);
            fileArra.push(files[i]);
            this.setState(
              {
                // selectedFile: URL.createObjectURL(e.target.files[i]),
                selectedFile: URL.createObjectURL(files[i]),
                attachment_file: fileArra,
                is_files_not_selected: false,
              },
              () => {},
            );

            // e.target.files = null;
            // e.target.value = '';
          } else {
            e.target.files = null;
            e.target.value = '';
            e.preventDefault();
            this.setState({
              isFailed: true,
              errormessage: 'Cannot select same file again',
            });

            setTimeout(() => {
              this.setState({ isFailed: false, errormessage: '' });
            }, 3000);
          }
        } else {
          e.target.files = null;
          e.target.value = '';
          e.preventDefault();
          this.setState({
            isFailed: true,
            errormessage: 'Unsupported file Format',
          });

          setTimeout(() => {
            this.setState({ isFailed: false, errormessage: '' });
          }, 3000);
        }

        if (i == files.length - 1) {
          if (this.state.attachment_file.length == 0) {
            // jQuery('.upload-space').removeClass('hide')
            jQuery('.upload-space').css({
              opacity: 1,
            });
            jQuery('.upload-thumb').addClass('hide');
          }
        }
      }
    }
  }
  save_attachment = () => {
    const client_id = this.state.logged_client_id;
    const type = 'journal';
    const source_attachments = this.state.attachment_file;
    console.log(source_attachments, 'resss');
    FetchAllApi.save_source_attachements(client_id, type, source_attachments, (err, response) => {
      if (response.status === 1) {
        this.setState(
          {
            attachment_file: [],
            list_of_attachments: [...this.state.list_of_attachments, ...response.data],
          },
          () => {
            jQuery('.previewImg').remove();
            window.jQuery('#upload_doc_modal').modal('hide');
            window.jQuery('#attachment_modal').modal('show');
            // jQuery('.upload-space').removeClass('hide')
            jQuery('.upload-space').css({
              opacity: 1,
            });

            jQuery('.upload-thumb').addClass('hide');
          },
        );
      } else {
        alert(response.message);
      }
    });
  };

  rename = (obj, curr) => {
    let a = {};
    Object.keys(obj).map(key => {
      let newKey = key.replace(curr, '');
      Object.assign(a, { [newKey]: obj[key] });
    });
    return a;
  };

  componentWillMount() {
    if (this.state.logged_user_id === '' || this.state.logged_user_id === null || this.state.logged_user_id === undefined) {
      this.props.history.push('/');
    }
  }

  logoutLink() {
    localStorage.clear();

    this.props.history.push('/');
  }

  createCopy = () => {
    localStorage.setItem('journal_id', this.state.journal_id);
    localStorage.setItem('is_create_copy_journal', 1);
    let win = window.open('/new_journal', '_blank');
    win.focus();
  };

  get_journal_details = journalid => {
    let input = {
      client_id: this.state.logged_client_id,
      journal_id: journalid,
    };
    FetchAllApi.get_journal_details(input, (err, response) => {
      if (response.status === 1) {
        localStorage.setItem('journal_id', '');
        localStorage.setItem('is_create_copy_journal', '');

        let a = response.details;

        let isEdit = true;
        let journal_id = a.id;
        if (this.state.is_create_copy_journal == 1) {
          isEdit = false;
          journal_id = '';
          this.setState({
            is_create_copy_journal: 0,
          });
        } else {
          this.setState({
            entry_no: a.journal_number,
          });
        }

        this.setState(
          {
            // isEdit: true,
            // journal_id: a.id,
            isEdit: isEdit,
            journal_id: journal_id,
            status: a.status, // 1 -publish, 2- Draft
            // entry_no: a.journal_number,
            journal_date: moment(a.journal_date, 'YYYY-MM-DD').format('DD/MM/YYYY'), // i think dummy
            date: moment(a.journal_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            narration: a.narration,
            journal_repeat_date:
              a.auto_reversing_date && a.auto_reversing_date != '0000-00-00'
                ? moment(a.auto_reversing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : '', // i think dummy
            auto_reversing_date:
              a.auto_reversing_date && a.auto_reversing_date != '0000-00-00'
                ? moment(a.auto_reversing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : '',

            //  1:a.show_default_narration,
            tax: a.tax_inclusive,
            selected_currency: a.foreign_currency,
            exchange_rate: a.exchange_rate,
            debitSubTotalHome: a.sub_total_debit_home_currency,
            list_of_attachments: a.source_attachments ? a.source_attachments : [],
            debitSubTotal: a.sub_total_debit_foreign_currency,

            grandTotalDebitHome: a.grand_total_debit_home_currency,
            grandTotalDebit: a.grand_total_debit_foreign_currency,
            grandTotalCredit: a.grand_total_credit_foreign_currency,
            grandTotalCreditHome: a.grand_total_credit_home_currency,
            creditSubTotalHome: a.sub_total_credit_home_currency,

            creditSubTotal: a.sub_total_credit_foreign_currency,
            taxTotalDebitHome: a.tax_total_debit_home_currency,
            taxTotalDebit: a.tax_total_debit_foreign_currency,
            taxTotalCreditHome: a.tax_total_credit_home_currency,
            taxTotalCredit: a.tax_total_credit_foreign_currency,
            is_home_currency_adjustment: a.is_home_currency_adjustment,

            logged_user_id: a.user_id,
            data_table: a.item_list,
          },
          () => {
            console.log(
              this.state.grandTotalDebitHome,
              this.state.grandTotalDebit,
              this.state.grandTotalCredit,
              this.state.grandTotalCreditHome,
            );
            console.log(
              a.grand_total_debit_home_currency,
              a.grand_total_debit_foreign_currency,
              a.grand_total_credit_foreign_currency,
              a.grand_total_credit_home_currency,
            );

            // commented on 21-04-2022
            // a.item_list && a.item_list.map((b,idx)=>{
            //    b.foreign_debit && b.foreign_debit != '' && this.changeDebit(idx, b.foreign_debit)
            //   b.foreign_credit && b.foreign_credit != '' && this.changeCredit(idx, b.foreign_credit)
            // })

            let deb_tax_array = [];
            let cre_tax_array = [];
            a.item_list &&
              a.item_list.map((b, idx) => {
                if (b.foreign_debit && b.foreign_debit != '') {
                  deb_tax_array.push(b.foreign_item_tax_debit);
                  cre_tax_array.push(0);
                }
                if (b.foreign_credit && b.foreign_credit != '') {
                  deb_tax_array.push(0);
                  cre_tax_array.push(b.foreign_item_tax_credit);
                }
              });
            console.log('deb_tax_array.......', deb_tax_array);
            console.log('cre_tax_array.......', cre_tax_array);

            this.setState({ totalTaxarrDebit: deb_tax_array, totalTaxarrCredit: cre_tax_array });
          },
        );
      } else {
        alert(response.message);
      }
      console.log(
        this.state.grandTotalDebitHome,
        this.state.grandTotalDebit,
        this.state.grandTotalCredit,
        this.state.grandTotalCreditHome,
      );
    });
  };

  reorderArray(dd, gg) {
    // Map each index in `dd` to the corresponding object in `gg`
    return dd.map(value => gg[parseInt(value, 10) - 1]);
  }

  componentDidMount() {
    // Initialize sortable
    window.jQuery(this.sortableRef.current).sortable({
      handle: '.drag-icon',
      update: (event, ui) => {
        if (this.sortableRef.current) {
          // Get all <tr> elements inside the <tbody>
          const rows = this.sortableRef.current.querySelectorAll('tr');

          // Extract the text content of the second <td> in each row
          const secondColumnValues = Array.from(rows).map(row => {
            const secondTd = row.querySelectorAll('td')[1]; // Get the second <td>
            return secondTd ? secondTd.textContent : null; // Return its text content
          });

          this.setState({ sortableOrder: secondColumnValues });
        }
      },
    });

    localStorage.setItem('added_account_id', '');
    localStorage.setItem('customerId', '');
    localStorage.setItem('third_party_customer_id', '');

    this.getCountry();
    this.get_client_home_currency();
    this.defaultcategorylist_onchange();
    this.addRow();
    this.fetchNames();
    this.allCustomerJobs();
    this.newJournalNextFunc();
    setInterval(() => {
      // if(localStorage.getItem("added_account_id") != ''){
      //   this.categoryChange(this.state.idx, localStorage.getItem("added_account_id"))
      // }
      // let isCustomer =
      // this.state.customer != undefined ? true : false;
      if (localStorage.getItem('customerId') != '' || localStorage.getItem('third_party_customer_id') != '') {
        let id =
          localStorage.getItem('customerId') != ''
            ? localStorage.getItem('customerId')
            : localStorage.getItem('third_party_customer_id');
        if (localStorage.getItem('customerId') != '') {
          this.fetchNames('added', id);
        } else {
          this.fetchNames('added', 'v' + id);
        }
        localStorage.setItem('customerId', '');
        localStorage.setItem('third_party_customer_id', '');
      }
    }, 3000);

    window.jQuery('.mscroll-y').mCustomScrollbar({
      axis: 'y',
      scrollEasing: 'linear',
      scrollInertia: 600,
      autoHideScrollbar: 'true',
      autoExpandScrollbar: 'true',
    });
    window.jQuery('.mscroll-x').mCustomScrollbar({
      axis: 'x',
      scrollEasing: 'linear',
      scrollInertia: 600,
      autoHideScrollbar: 'true',
      autoExpandScrollbar: 'true',
    });
  }

  componentDidUpdate() {
    window.jQuery('.selectpicker').selectpicker('refresh');
    window.jQuery('.input-group.date').datepicker({ format: 'dd/mm/yyyy', autoclose: true });
  }

  changeValue = e => {
    this.setState({ [e.target.name]: e.target.value }, this.currencyLoop, this.grandTotalCreditFunc, this.grandTotalDebitFunc);
    let table = [...this.state.data_table];
    for (var i = 0; i < table.length; i++) {
      if (table[i].debit !== '0') {
        table[i].debit = table[i].foreign_debit * e.target.value;
      }
      if (table[i].credit !== '0') {
        table[i].credit = table[i].foreign_credit * e.target.value;
      }
    }
    this.setState({ data_table: table });
  };

  setDate = e => {
    let val = jQuery('#date').val();
    if (val != this.state.date) {
      this.setState({ date: val }, () => {
        this.exchangeRateChange(true);
        this.reviceCondiFunc();
      });
    }
  };

  deleteSetDate = e => {
    jQuery('#date').val('');
    this.setState({ date: '' });
  };

  reviceDateFunc = e => {
    let val = jQuery('#revise_date').val();
    console.log('value', val);
    this.setState({ auto_reversing_date: val }, this.reviceCondiFunc);
  };

  deleteReviceDateFunc = e => {
    jQuery('#revise_date').val('');
    this.setState({ auto_reversing_date: '' }, this.reviceCondiFunc);
  };

  reviceCondiFunc = () => {
    let date1 = this.state.date;
    let date2 = this.state.auto_reversing_date;
    let change1 = date1.split('/');
    let change2 = date2.split('/');
    let format1 = change1[1] + '/' + change1[0] + '/' + change1[2];
    let format2 = change2[1] + '/' + change2[0] + '/' + change2[2];
    if (date1 !== '' && date2 !== '') {
      if (
        new Date(format1).getTime() > new Date(format2).getTime() ||
        new Date(format1).getTime() == new Date(format2).getTime()
      ) {
        this.setState({ auto_reversing_date: '' });
        alert('Reversing date should be greater than entry date');
      }
    }
  };

  narrationChange = e => {
    let value = e.target.value;
    this.setState({ narration: value }, () => {
      this.changeMemo(-1, value, false);
      // let value = e.target.value;
    });
  };

  currencyChange = e => {
    this.setState({ selected_currency: e.target.value }, () => {
      this.exchangeRateChange(false);
    });
  };

  exchangeRateChange = async is_date_changed => {
    let date = this.state.date ? this.state.date : '';
    if (date != undefined && date != null && date != '') {
      var date_formated = moment().format('YYYY-MM-DD');
      var array = date.split('/');
      date_formated = array[2] + '-' + array[1] + '-' + array[0];

      let todayDate = new Date();
      todayDate.setHours(23, 59, 59, 998);
      if (new Date(date_formated).getTime() > todayDate.getTime()) {
        if (is_date_changed == true) {
          if (this.state.selected_currency == this.state.clientHomeCurrency) {
            Swal.fire('You are entering a transaction for Future Date!');
          } else {
            Swal.fire(
              'You are entering a transaction for Future Date!',
              "Since you are entering a transaction for future date, The exchange rate will follow today's date And you must change the exchange rate manually for the Transaction",
            );
          }
        }
        date_formated = moment().format('YYYY-MM-DD');
      }

      if (this.state.selected_currency && this.state.selected_currency != '') {
        let nope = `https://api.currencylayer.com/timeframe?access_key=${config.api_key}&start_date=${date_formated}&end_date=${date_formated}&currencies=${this.state.clientHomeCurrency}&source=`;
        let exchangeReq = nope.concat(this.state.selected_currency);

        await fetch(exchangeReq)
          .then(async response => await response.json())
          .then(async data => {
            if (data.success == true) {
              let newObj = this.rename(data.quotes, this.state.selected_currency);
              let currencyAr = [];
              let first = newObj;
              currencyAr.push({
                date: data.start_date,
                // 'rate': isNaN(first[data.start_date][this.state.selected_currency + this.state.clientHomeCurrency])? 0.0000 :(first[data.start_date][this.state.selected_currency + this.state.clientHomeCurrency]).toFixed(4)
                rate:
                  first[data.start_date] && first[data.start_date][this.state.selected_currency + this.state.clientHomeCurrency]
                    ? first[data.start_date][this.state.selected_currency + this.state.clientHomeCurrency].toFixed(4)
                    : parseFloat(1).toFixed(4),
              });
              let value = Number(currencyAr[0].rate).toFixed(4);
              // condition newly added on 2024-05-30
              if (this.state.is_home_currency_adjustment != true) {
                this.setState({ exchange_rate: value }, () => {
                  this.currencyLoop();
                });
              } else {
                this.setState({ exchange_rate: 1 }, () => {
                  this.currencyLoop();
                });
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        console.log('Please select currency...to get exchange rate');
      }
    } else {
      console.log('Please verify selected journal date is correct');
    }

    // let rate = this.state.currency[this.state.selected_currency]
    // let rateChange = Number(parseFloat((1 / rate).toFixed(4)))
    // this.setState({ exchange_rate: rateChange }, this.currencyLoop)
  };

  currencyLoop = () => {
    if (this.state.data_table[0].debit !== '0' || this.state.data_table[0].credit !== '0') {
      let table = [...this.state.data_table];
      for (var i = 0; i < this.state.data_table.length; i++) {
        //changed on 21-04-2022
        // this.excludingTaxTotalDebit(i, "lop")
        // this.excludingTaxTotalCredit(i, "lop")

        if (table[i].foreign_debit !== '') {
          // table[i].debit = parseFloat(table[i].foreign_debit) * this.state.exchange_rate;
          this.changeDebit(i, table[i].foreign_debit);
        }

        if (table[i].foreign_credit !== '') {
          //table[i].credit = parseFloat(table[i].foreign_credit) * this.state.exchange_rate;
          this.changeCredit(i, table[i].foreign_credit);
        }
        // this.setState({data_table:table},()=>{
        //   this.excludingTaxTotalDebit(i)
        //   this.excludingTaxTotalCredit(i)
        // });
      }
    } else {
      this.excludingTaxTotalDebit(0);
      this.excludingTaxTotalCredit(0);
    }
  };
  // newchanges 2024126
  changeName = (idx, e) => {
    // const obj = this.state.namelist.find(item => item.id === e);
    const obj = this.state.namelist.find(item => item.id == e);
    if (!obj) return;

    this.setState(prevState => {
      const updatedTable = [...prevState.data_table];
      updatedTable[idx] = {
        ...updatedTable[idx],
        name: obj.id,
        name_text: obj.name,
        name_type: obj.type,
        selected_job_id: 0,
      };

      return { data_table: updatedTable };
    });
  };

  // changeName = (idx, e) => {
  //   let obj = this.state.namelist.find(itm => e == itm.id);
  //   let table = [...this.state.data_table];
  //   table[idx].name = obj.id;
  //   table[idx].name_text = obj.name;
  //   table[idx].name_type = obj.type;
  //   table[idx].selected_job_id = 0;
  //   this.setState({ data_table: table });
  // };

  changeJob = (idx, e) => {
    let obj = this.state.jobList.find(itm => e == itm.job_id);
    let table = [...this.state.data_table];
    table[idx].selected_job_id = obj.job_id;
    this.setState({ data_table: table });
  };

  changeMemo = (idx, e, isCheckBoxChanged = false) => {
    let table = [...this.state.data_table];

    if (idx != -1) {
      // if (this.state.check == true) {
      table[idx].descr = e;
      // }
      // else {
      //   table[idx].descr = '';
      // };
    } else if (idx == -1 && isCheckBoxChanged == false) {
      table.forEach((data, ind) => {
        if (this.state.check == true) {
          table[ind].descr = e;
        }
      });
    } else if (idx == -1 && isCheckBoxChanged == true) {
      table.forEach((data, ind) => {
        if (this.state.check == true) {
          table[ind].descr = e;
        } else {
          table[ind].descr = '';
        }
      });
    }

    this.setState({ data_table: table });
  };

  categoryChange = (idx, e) => {
    let table = [...this.state.data_table];
    table[idx].category = e;
    this.setState({ data_table: table });
  };

  ioTaxTypeChange = (idx, e) => {
    let table = [...this.state.data_table];
    table[idx].io_type_of_tax = e;
    this.setState({ data_table: table });
  };

  taxClick = (idx, value) => {
    let table = [...this.state.data_table];
    table[idx].selectedTax = value;
    this.setState({ data_table: table });
    this.state.gst_list.map(data => {
      if (data.id == value) {
        table[idx].tax_name = data.sales_tax_name;
        table[idx].tax_rate = data.rate;

        table[idx].tax_type = data.rate_type;
        this.setState({ data_table: table }, () => {
          this.excludingTaxTotalDebit(idx);
          this.excludingTaxTotalCredit(idx);
        });
      }
    });
  };

  changeCredit = (idx, e) => {
    let table = [...this.state.data_table];
    let tax = [...this.state.totalTaxarrDebit];
    table[idx].foreign_credit = e;
    // table[idx].foreign_debit = 0
    table[idx].foreign_debit = '';
    if (tax[idx] !== 0) {
      tax[idx] = 0;
    }
    table[idx].credit = e * this.state.exchange_rate;
    table[idx].debit = '';
    this.setState({ data_table: table, totalTaxarrDebit: tax }, () => {
      this.excludingTaxTotalCredit(idx);
      this.excludingTaxTotalDebit(idx);
    });
  };

  changeDebit = (idx, e) => {
    // console.log('mmnm', e);
    let table = [...this.state.data_table];
    let tax = [...this.state.totalTaxarrCredit];
    console.log(tax[idx]);
    table[idx].foreign_debit = e;
    // table[idx].foreign_credit = 0
    table[idx].foreign_credit = '';
    if (tax[idx] !== 0) {
      tax[idx] = 0;
    }
    table[idx].debit = e * this.state.exchange_rate;
    table[idx].credit = '';
    this.setState({ data_table: table, totalTaxarrCredit: tax }, () => {
      this.excludingTaxTotalDebit(idx);
      this.excludingTaxTotalCredit(idx);
    });
  };

  checkChange = val => {
    this.setState({ check: val }, () => {
      this.changeMemo(-1, this.state.narration, true);
      //  this.changeMemo(1, this.state.narration)
    });
  };
  checkAdjustment = val => {
    let exchange_rate = val == true ? 1 : 0;
    this.setState({ is_home_currency_adjustment: val, exchange_rate: exchange_rate }, () => {
      if (val == false) {
        this.exchangeRateChange(true);
      }
    });
  };

  addRow = (number_of_rows_to_add = 1) => {
    let val = [...this.state.data_table];
    if (this.state.check == true) {
      for (let k = 0; k < number_of_rows_to_add; k++) {
        val.push({
          descr: this.state.narration,
          name: '',
          debit: '',
          credit: '',
          foreign_credit: '',
          foreign_debit: '',
          selectedTax: '',
          category: '',
          tax_name: '',
          tax_rate: 0,
          tax_type: '',
          item_tax_debit: '',
          item_tax_credit: '',
          foreign_item_tax_credit: '',
          foreign_item_tax_debit: '',
        });
      }
    } else {
      for (let k = 0; k < number_of_rows_to_add; k++) {
        val.push({
          descr: '',
          name: '',
          debit: '',
          credit: '',
          foreign_credit: '',
          foreign_debit: '',
          selectedTax: '',
          category: '',
          tax_name: '',
          tax_rate: 0,
          tax_type: '',
          item_tax_debit: '',
          item_tax_credit: '',
          foreign_item_tax_credit: '',
          foreign_item_tax_debit: '',
        });
      }
    }
    this.setState({ data_table: val });
  };

  copyRow = idx => {
    let copy = [...this.state.data_table];
    let val = copy[idx];
    copy.push({
      descr: val.descr,
      name: val.name,
      debit: val.debit,
      credit: val.credit,
      foreign_credit: val.foreign_credit,
      foreign_debit: val.foreign_debit,
      selectedTax: val.selectedTax,
      category: val.category,
      tax_name: val.tax_name,
      tax_rate: val.tax_rate,
      tax_type: val.tax_type,
      item_tax_debit: val.item_tax_debit,
      item_tax_credit: val.item_tax_credit,
      foreign_item_tax_credit: val.foreign_item_tax_credit,
      foreign_item_tax_debit: val.foreign_item_tax_debit,
    });
    this.setState({ data_table: copy }, () => {
      this.excludingTaxTotalCredit(idx);
      this.excludingTaxTotalDebit(idx);
    });
  };

  excludingTaxTotalDebit = (idx, lop) => {
    let table = [...this.state.data_table];
    let taxarr = [...this.state.totalTaxarrDebit];
    let taxin;
    console.log('debit', table, taxarr);
    // if (table[idx].foreign_Debit !== "") {
    if (table[idx].foreign_debit !== '') {
      //callback added
      this.setState({ indexdebit: idx }, () => {
        let subtarr;
        if (lop !== undefined) {
          taxin = this.taxindeb;
          subtarr = this.subtdeb;
        } else {
          taxin = [...this.state.taxincluarrDebit];
          subtarr = [...this.state.debitSubTotalarr];
        }
        let cal;
        let sum;
        let subt;
        let tax;
        if (this.state.including_tax == true) {
          if (table[idx].foreign_debit !== '' && table[idx].foreign_debit !== 0) {
            if (this.state.indexdebit == idx) {
              subt = table[idx].foreign_debit * (100 / (100 + table[idx].tax_rate));
              table[idx].foreign_item_tax_debit = subt;
              table[idx].item_tax_debit = subt * this.state.exchange_rate;
              this.setState({ data_table: table });
              subtarr.splice(idx, 1, subt);
              sum = subtarr.reduce(function (prev, current) {
                return prev + +current;
              }, 0);
              let equ = table[idx].foreign_debit - subtarr[idx];
              taxin.splice(idx, 1, equ);
              tax = taxin.reduce(function (prev, current) {
                return prev + +current;
              }, 0);

              let mul = this.state.exchange_rate * sum;
              let taxmul = this.state.exchange_rate * (!isNaN(tax) ? tax : 0);

              //22-11-2022
              // taxmul = parseFloat(taxmul.toFixed(2));

              this.setState(
                {
                  debitSubTotal: sum,
                  taxTotalDebit: !isNaN(tax) ? tax : 0,
                  debitSubTotalarr: subtarr,
                  taxincluarrDebit: taxin,
                  debitSubTotalHome: mul,
                  taxTotalDebitHome: taxmul,
                },
                this.grandTotalDebitFunc,
              );
            } else {
              subt = table[idx].foreign_debit * (100 / (100 + table[idx].tax_rate));
              table[idx].foreign_item_tax_debit = subt;
              table[idx].item_tax_debit = subt * this.state.exchange_rate;
              this.setState({ data_table: table });
              subtarr[idx] = subt;
              sum = subtarr.reduce(function (prev, current) {
                return prev + +current;
              }, 0);
              let equ = table[idx].foreign_debit - subtarr[idx];
              taxin[idx] = equ;
              tax = taxin.reduce(function (prev, current) {
                return prev + +current;
              }, 0);
              let mul = this.state.exchange_rate * sum;
              let taxmul = this.state.exchange_rate * (!isNaN(tax) ? tax : 0);
              this.setState(
                {
                  debitSubTotal: sum,
                  taxTotalDebit: !isNaN(tax) ? tax : 0,
                  debitSubTotalarr: subtarr,
                  taxincluarrDebit: taxin,
                  debitSubTotalHome: mul,
                  taxTotalDebitHome: taxmul,
                },
                this.grandTotalDebitFunc,
              );
            }
          }
        } else {
          sum = table.reduce(function (prev, current) {
            return prev + +current.foreign_debit;
          }, 0);
          console.log('sum', sum);
          if (this.state.indexdebit == idx) {
            if (table[idx].foreign_debit !== '' && table[idx].foreign_debit !== 0) {
              cal = (table[idx].tax_rate / 100) * table[idx].foreign_debit;
              table[idx].foreign_item_tax_debit = cal;
              table[idx].item_tax_debit = cal * this.state.exchange_rate;
              this.setState({ data_table: table });
              taxarr.splice(idx, 1, cal);
              tax = taxarr.reduce(function (prev, current) {
                return prev + +current;
              }, 0);
              let mul = this.state.exchange_rate * sum;
              let taxmul = this.state.exchange_rate * (!isNaN(tax) ? tax : 0);
              console.log('mul,taxmul', mul, taxmul);
              this.setState(
                {
                  debitSubTotal: sum,
                  taxTotalDebit: !isNaN(tax) ? tax : 0,
                  totalTaxarrDebit: taxarr,
                  debitSubTotalHome: mul,
                  taxTotalDebitHome: taxmul,
                },
                this.grandTotalDebitFunc,
              );
            } else {
              cal = (table[idx].tax_rate / 100) * table[idx].foreign_debit;
              table[idx].foreign_item_tax_debit = cal;
              table[idx].item_tax_debit = cal * this.state.exchange_rate;
              this.setState({ data_table: table });
              taxarr[idx] = cal;
              tax = taxarr.reduce(function (prev, current) {
                return prev + +current;
              }, 0);

              let mul = this.state.exchange_rate * sum;
              let taxmul = this.state.exchange_rate * (!isNaN(tax) ? tax : 0);
              console.log('mul,taxmul', mul, taxmul);
              this.setState(
                {
                  debitSubTotal: sum,
                  taxTotalDebit: !isNaN(tax) ? tax : 0,
                  totalTaxarrDebit: taxarr,
                  debitSubTotalHome: mul,
                  taxTotalDebitHome: taxmul,
                },
                this.grandTotalDebitFunc,
              );
            }
          } else {
            console.log('616............***this.state.indexdebit', this.state.indexdebit);
            console.log('616............***idx', idx);
          }
        }
      });
    }
  };

  excludingTaxTotalCredit = (idx, lop) => {
    let table = [...this.state.data_table];
    let taxarr = [...this.state.totalTaxarrCredit];
    let taxin;
    if (table[idx].foreign_credit !== '') {
      this.setState({ indexcredit: idx });
    }
    let subtarr;
    if (lop !== undefined) {
      taxin = this.taxincre;
      subtarr = this.subtcre;
    } else {
      taxin = [...this.state.taxincluarrCredit];
      subtarr = [...this.state.creditSubTotalarr];
    }
    let cal;
    let sum;
    let subt;
    let tax;
    if (this.state.including_tax == true) {
      if (table[idx].foreign_credit !== '') {
        if (this.state.indexcredit == idx) {
          subt = table[idx].foreign_credit * (100 / (100 + table[idx].tax_rate));
          table[idx].foreign_item_tax_credit = subt;
          table[idx].item_tax_credit = subt * this.state.exchange_rate;
          this.setState({ data_table: table });
          subtarr.splice(idx, 1, subt);
          sum = subtarr.reduce(function (prev, current) {
            return prev + +current;
          }, 0);
          let equ = table[idx].foreign_credit - subtarr[idx];
          taxin.splice(idx, 1, equ);
          tax = taxin.reduce(function (prev, current) {
            return prev + +current;
          }, 0);
          let mul = this.state.exchange_rate * sum;
          let taxmul = this.state.exchange_rate * (!isNaN(tax) ? tax : 0);
          this.setState(
            {
              creditSubTotal: sum,
              taxTotalCredit: !isNaN(tax) ? tax : 0,
              creditSubTotalarr: subtarr,
              taxincluarrCredit: taxin,
              creditSubTotalHome: mul,
              taxTotalCreditHome: taxmul,
            },
            this.grandTotalCreditFunc,
          );
        } else {
          subt = table[idx].foreign_credit * (100 / (100 + table[idx].tax_rate));
          table[idx].foreign_item_tax_credit = subt;
          table[idx].item_tax_credit = subt * this.state.exchange_rate;
          this.setState({ data_table: table });
          subtarr[idx] = subt;
          sum = subtarr.reduce(function (prev, current) {
            return prev + +current;
          }, 0);
          let equ = table[idx].foreign_credit - subtarr[idx];
          taxin[idx] = equ;
          tax = taxin.reduce(function (prev, current) {
            return prev + +current;
          }, 0);
          let mul = this.state.exchange_rate * sum;
          let taxmul = this.state.exchange_rate * (!isNaN(tax) ? tax : 0);
          this.setState(
            {
              creditSubTotal: sum,
              taxTotalCredit: !isNaN(tax) ? tax : 0,
              creditSubTotalarr: subtarr,
              taxincluarrCredit: taxin,
              creditSubTotalHome: mul,
              taxTotalCreditHome: taxmul,
            },
            this.grandTotalCreditFunc,
          );
        }
      }
    } else {
      sum = table.reduce(function (prev, current) {
        return prev + +current.foreign_credit;
      }, 0);
      if (this.state.indexcredit == idx) {
        cal = (table[idx].tax_rate / 100) * table[idx].foreign_credit;
        table[idx].foreign_item_tax_credit = cal;
        table[idx].item_tax_credit = cal * this.state.exchange_rate;
        this.setState({ data_table: table });
        taxarr.splice(idx, 1, cal);
        tax = taxarr.reduce(function (prev, current) {
          return prev + +current;
        }, 0);
        let mul = this.state.exchange_rate * sum;
        let taxmul = this.state.exchange_rate * (!isNaN(tax) ? tax : 0);
        this.setState(
          {
            creditSubTotal: sum,
            taxTotalCredit: !isNaN(tax) ? tax : 0,
            totalTaxarrCredit: taxarr,
            creditSubTotalHome: mul,
            taxTotalCreditHome: taxmul,
          },
          this.grandTotalCreditFunc,
        );
      } else {
        cal = (table[idx].tax_rate / 100) * table[idx].foreign_credit;
        table[idx].foreign_item_tax_credit = cal;
        table[idx].item_tax_credit = cal * this.state.exchange_rate;
        this.setState({ data_table: table });
        taxarr[idx] = cal;
        tax = taxarr.reduce(function (prev, current) {
          return prev + +current;
        }, 0);
        let mul = this.state.exchange_rate * sum;
        let taxmul = this.state.exchange_rate * (!isNaN(tax) ? tax : 0);
        this.setState(
          {
            creditSubTotal: sum,
            taxTotalCredit: !isNaN(tax) ? tax : 0,
            totalTaxarrCredit: taxarr,
            creditSubTotalHome: mul,
            taxTotalCreditHome: taxmul,
          },
          this.grandTotalCreditFunc,
        );
      }
    }
  };

  deleteRow = idx => {
    console.log('del', idx);
    let data = [...this.state.data_table];
    if (data.length > 2) {
      data.splice(idx, 1);
      this.setState({ data_table: data }, this.afterDelFunc);
    }
  };

  afterDelFunc = () => {
    for (var i = 0; i < this.state.data_table.length; i++) {
      this.excludingTaxTotalDebit(i);
      this.excludingTaxTotalCredit(i);
    }
  };

  grandTotalCreditFunc = () => {
    let creditTotal = parseFloat(this.state.creditSubTotal) + parseFloat(this.state.taxTotalCredit);
    let creditTotalHome = parseFloat(this.state.creditSubTotalHome) + parseFloat(this.state.taxTotalCreditHome);
    this.setState({ grandTotalCredit: creditTotal, grandTotalCreditHome: creditTotalHome });
  };

  grandTotalDebitFunc = () => {
    let debitTotal = parseFloat(this.state.debitSubTotal) + parseFloat(this.state.taxTotalDebit);
    let debitTotalHome = parseFloat(this.state.debitSubTotalHome) + parseFloat(this.state.taxTotalDebitHome);

    this.setState({ grandTotalDebit: debitTotal, grandTotalDebitHome: debitTotalHome }, () => {
      console.log(this.state.debitSubTotal, this.state.taxTotalDebit, debitTotal, debitTotalHome);
    });
  };

  taxAdd = e => {
    console.log(e.target.checked);
    let arr = [...this.state.data_table];
    this.setState({ including_tax: e.target.checked }, this.incluOrExcluFunc);
  };

  incluOrExcluFunc = () => {
    if (this.state.including_tax == true) {
      if (this.state.data_table[0].debit !== '0' && this.state.data_table[0].credit !== '0') {
        for (var i = 0; i < this.state.data_table.length; i++) {
          this.excludingTaxTotalDebit(i, 'lop');
          this.excludingTaxTotalCredit(i, 'lop');
        }
      }
    } else {
      this.excludingTaxTotalDebit(0);
      this.excludingTaxTotalCredit(0);
    }
  };

  handleChangeTax(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };

  handleCheck_get_selected_tax(selectednow_id, itemid, id, valueres, rate, type) {
    console.log('selectednow_id', id);
    if (selectednow_id > 0) {
      jQuery('#' + id).html(valueres);
      jQuery('#selectedrate_id' + itemid).val(rate);
      jQuery('#selectedtype_id' + itemid).val(type);
      jQuery('#selectednow_id' + itemid).html(selectednow_id);

      this.handleChangeItems(valueres, this.state.rows.length - 1);

      jQuery('.form-table').removeClass('ovrFlwRmve');
    } else {
      alert('sorry fault is here only');
    }

    jQuery('#gst_search').val('');
    this.get_gst_list();
  }

  add_gst_details = e => {
    e.preventDefault();
    let sales_tax_code = this.state.taxCode;
    let sales_tax_name = this.state.taxName;
    let show_on_list = 1;
    let tax_type = this.state.taxType === 'option1' ? 1 : 2;
    let rate = this.state.taxRate;
    let country = this.state.country_code;
    if (this.state.selected_rate_type != 'Fixed price' && this.state.selected_rate_type === '%') {
      var rate_type = 1;
    } else {
      var rate_type = 2;
    }

    let items = {
      sales_tax_code: sales_tax_code,
      sales_tax_name: sales_tax_name,
      show_on_list: show_on_list,
      tax_type: tax_type,
      rate: rate,
      rate_type: rate_type,
      country: country,
      client_id: this.state.logged_client_id,
    };
    console.log('hjagsjkhlkasjh', items);
    FetchAllApi.add_gst_details(items, (err, response) => {
      console.log('add comment', response.status);

      if (response.status === 1) {
        jQuery('#sales_tax_code').val('');
        jQuery('#sales_tax_name').val('');
        jQuery('#sales_tax_name').val('');
        jQuery('#tax').val('');
        this.setState({ show_succes: true });
        this.get_gst_list();
        var THIS = this;
        setTimeout(function () {
          THIS.setState({ show_succes: false });
          THIS.taxClick(THIS.state.idx, response.id);
        }, 4000);
        window.jQuery('#pop-modal-1').modal('hide');
      } else {
        this.setState({ modal_info_msg: response.message });
        jQuery('.mymsg').fadeIn(2000);
        setTimeout(function () {
          jQuery('.mymsg').fadeOut(2000);
        }, 8000);
      }
    });
  };

  newJournalNextFunc = () => {
    let client_Id = this.state.logged_client_id;
    console.log(this.props.location.state);
    FetchAllApi.get_manual_journal_next_number(client_Id, (err, response) => {
      if (response.status === 1) {
        this.setState(
          {
            entry_no: response.manual_journal_number,
          },
          () => {
            if (this.props.location.state != '' && this.props.location.state != null && this.props.location.state != undefined) {
              this.get_journal_details(this.props.location.state);
            } else if (localStorage.getItem('journal_id') != undefined && localStorage.getItem('journal_id') != '') {
              if (localStorage.getItem('is_create_copy_journal')) {
                this.setState({
                  is_create_copy_journal: localStorage.getItem('is_create_copy_journal'),
                });
              }
              this.get_journal_details(localStorage.getItem('journal_id'));
            }
          },
        );
        var defaultCurrency = localStorage.getItem('default_currency');
        this.setState({ selected_currency: defaultCurrency }, () => {
          this.exchangeRateChange(false);
        });
      }
    });
  };

  update_search_keyword = event => {
    this.setState({ search_key_gst: event.target.value }, () => {
      this.get_gst_list();
    });
  };

  get_gst_list = () => {
    let country_code = this.state.country_code;
    //alert(country_code)
    let keyword = this.state.search_key_gst;
    let filter_id = 2;

    FetchAllApi.get_gst_list(country_code, keyword, this.state.logged_client_id, filter_id, (err, response) => {
      console.log('defaultcategorylist', response);
      //alert(response.message)
      if (response.status === 1) {
        this.setState({
          gst_list: response.list,
        });
      } else {
        this.setState({
          gst_list: [],
        });
      }
    });
  };

  update_rate_fixed = () => {
    jQuery('#tax').val('');
    this.setState({
      selected_rate_type: 'Fixed price',
      maximum_chr_lngth: 100,
    });
  };
  update_rate_type = () => {
    jQuery('#tax').val('');
    this.setState({
      selected_rate_type: '%',
      maximum_chr_lngth: 4,
    });
  };
  getCountry = () => {
    let client_Id = this.state.logged_client_id;
    FetchAllApi.get_country_id(client_Id, (err, response) => {
      // alert(response.country_id)
      if (response.status === 1) {
        this.setState(
          {
            country_code: response.country_id,
          },
          () => this.get_gst_list(),
        );
      }
    });
  };

  modal_cancel = () => {
    jQuery('#sales_tax_code').val('');
    jQuery('#sales_tax_name').val('');
    jQuery('#sales_tax_name').val('');
    jQuery('#tax').val('');
    this.setState({ modal_info_msg: '' });
    window.jQuery('#pop-modal-1').modal('hide');
  };

  handleChange_gst_type = event => {
    if (this.state.selected_rate_type != 'Fixed price') {
      let entered_value = event.target.value;
      console.log(isNaN(entered_value));
      // alert(entered_value)
      if (isNaN(entered_value)) {
        jQuery('#tax').val('');
      } else {
        if (entered_value > 100) {
          jQuery('#tax').val('');
        } else {
          this.setState({ taxRate: entered_value });
        }
      }
    } else {
      let entered_value = event.target.value;
      if (isNaN(entered_value)) {
        jQuery('#tax').val('');
      } else {
        this.setState({ taxRate: entered_value });
      }
    }
  };

  get_currencies = () => {
    fetch(
      // `https://api.exchangerate-api.com/v4/latest/${this.state.clientHomeCurrency}`
      `https://api.currencylayer.com/live?access_key=${config.api_key}&source=${this.state.clientHomeCurrency}`,
    )
      .then(response => response.json())
      .then(data => {
        let newObj = this.rename(data.quotes, this.state.clientHomeCurrency);

        const currencyAr = [];
        let first = newObj;
        for (const key in first) {
          currencyAr.push(key);
        }
        // this.setState({ currency_list: currencyAr, currency: first });
      });
  };

  get_client_home_currency = () => {
    let client_id = this.state.logged_client_id;

    FetchAllApi.get_client_home_currency(client_id, (err, response) => {
      if (response.status === 1) {
        console.log('Basio state', response);
        this.setState(
          {
            clientHomeCurrency: response.currency,
          },
          this.get_currencies,
        );
      } else {
      }
    });
  };

  defaultcategorylist_onchange = (x, y) => {
    let keyy = '';
    let from_create_invoice = 1;
    var client_id = this.state.logged_client_id;
    let filter_id = 2;

    FetchAllApi.manual_journal_defaultcategorylist(keyy, from_create_invoice, client_id, filter_id, (err, response) => {
      if (response.status === 1) {
        if (x == 'added') {
          let id = response.list.find(e => e.name == y || e.original_name == y);
          this.categoryChange(this.state.idx, id.id);
          this.setState({
            selectNeedIndex: response.list.length - 1,
            nameFilter: y,
          });
        }
        this.setState(
          {
            default_category_list: response.list,
          },
          () => {
            window.jQuery('#categry_id0').selectpicker('refresh');
          },
        );
      } else {
        this.setState({
          default_category_list: [],
        });
      }
    });
  };

  fetchNames = (a, id) => {
    let client_Id = this.state.logged_client_id;
    let filter_id = 2;
    FetchAllApi.customer_and_vendor_list_for_journal(client_Id, filter_id, (err, response) => {
      console.log(response.list, ' response.list ');
      if (response.status === 1) {
        this.setState({ namelist: response.list });
        if (a == 'added') {
          this.changeName(this.state.idx, id);
        }
      }
    });
  };

  allCustomerJobs = () => {
    let client_id = this.state.logged_client_id;
    FetchAllApi.allCustomerJobs(client_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ jobList: response.list });
      } else {
        this.setState({ jobList: [] });
      }
    });
  };

  // new changes 2024126
  saveEmp = id => {
    let input = { client_id: this.state.logged_client_id, employee_name: this.state.new_name };
    FetchAllApi.add_employee_with_name(input, (err, response) => {
      if (response?.status === 1) {
        this.fetchNames('added', 'e' + response.id);
      }
    });
  };

  saveOthers = id => {
    let input = { client_id: this.state.logged_client_id, name: this.state.new_name };
    FetchAllApi.add_other_staff(input, (err, response) => {
      if (response?.status === 1) {
        this.fetchNames('added', 'O' + response.id);
      }
    });
  };

  // saveEmp = (id) => {
  //   let input = { client_id: this.state.logged_client_id, employee_name: this.state.new_name };
  //   FetchAllApi.add_employee_with_name(input, (input, response) => {
  //     if (response.status === 1) {
  //       this.fetchNames('added', 'e' + response.id);
  //       // setTimeout(() => {
  //       // this.changeName(this.state.idx, id)
  //       // }, 1000);
  //     }
  //   });
  // };

  // saveOthers = (id) => {
  //   let input = { client_id: this.state.logged_client_id, name: this.state.new_name };
  //   FetchAllApi.add_other_staff(input, (err, response) => {
  //     if (response.status === 1) {
  //       this.fetchNames('added', 'O' + response.id);
  //       // setTimeout(() => {
  //       // this.changeName(this.state.idx, id)
  //       // }, 1000);
  //     }
  //   });
  // };
  makevoid = d => {
    FetchAllApi.make_voidmanualjournal(this.state.logged_client_id, this.state.journal_id, (err, response) => {
      if (response.status === 1) {
        alert(response.message);
        this.props.history.push('/manual_journal');
      } else {
        alert(response.message);
      }
    });
  };
  saveClick = (val1, val2) => {
    console.log('val1..........................1189___', val1);
    console.log('val2..........................1190___', val2);

    let status;
    if (val1 != '') this.setState({ savestatus: val1 });
    if (val1 == 'post') {
      status = 1;
    } else if (val1 == '') {
      status = this.state.savestatus == 'post' ? 1 : 2;
    } else {
      status = 2;
    }
    let journal_date;
    let journal_repeat_date;
    let date = this.state.date;
    let date1 = this.state.auto_reversing_date;
    let tax;
    if (this.state.including_tax == true) {
      tax = 1;
    } else {
      tax = 0;
    }
    if (date !== undefined && date !== '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      journal_date = date_formated;
    }
    if (date1 !== undefined && date1 !== '') {
      var array = date1.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      journal_repeat_date = date_formated;
    }

    let source_attachment_ids = this.state.list_of_attachments.map(obj => obj.source_id);
    let source_attachments = source_attachment_ids.join(',');

    let input = {
      isEdit: this.state.isEdit,
      journal_id: this.props.location.state ? this.props.location.state : this.state.journal_id,
      client_id: this.state.logged_client_id,
      type: 1,
      status: status, // 1 -publish, 2- Draft
      journal_number: this.state.entry_no,
      journal_date: journal_date,
      narration: this.state.narration,
      auto_reversing_date: journal_repeat_date,
      show_default_narration: 1,
      tax_inclusive: tax,
      foreign_currency: this.state.selected_currency,
      exchange_rate: this.state.exchange_rate,
      sub_total_debit_home_currency: this.state.debitSubTotalHome,
      tax_total_debit_home_currency: this.state.taxTotalDebitHome,
      grand_total_debit_home_currency: this.state.grandTotalDebitHome,
      sub_total_debit_foreign_currency: this.state.debitSubTotal,
      tax_total_debit_foreign_currency: this.state.taxTotalDebit,
      grand_total_debit_foreign_currency: this.state.grandTotalDebit,
      sub_total_credit_home_currency: this.state.creditSubTotalHome,
      tax_total_credit_home_currency: this.state.taxTotalCreditHome,
      grand_total_credit_home_currency: this.state.grandTotalCreditHome,
      sub_total_credit_foreign_currency: this.state.creditSubTotal,
      tax_total_credit_foreign_currency: this.state.taxTotalCredit,
      grand_total_credit_foreign_currency: this.state.grandTotalCredit,
      is_home_currency_adjustment: this.state.is_home_currency_adjustment ? 1 : 0,
      user_id: this.state.logged_user_id,
      item_list: this?.state?.sortableOrder
        ? this.reorderArray(this?.state?.sortableOrder, this.state.data_table)
        : this.state.data_table,
      lock_date_password: this.state.old_password,
      source_attachments,
    };
    if (val1 == 'post') {
      if (
        this.state.narration !== '' &&
        this.state.entry_no !== '' &&
        journal_date !== '' &&
        this.state.selected_currency !== ''
      ) {
        // console.log('manoj', this.state.grandTotalCredit, this.state.grandTotalDebit)
        // if (this.state.grandTotalCredit == this.state.grandTotalDebit) {
        this.setState({ loading: true });
        FetchAllApi.post_new_journal(input, (err, response) => {
          if (response.status === 1) {
            this.setState({ loading: false });
            if (val2 != 'new') {
              this.setState({
                journal_id: response.journal_id,
                isEdit: true, //newly added on for create copy purpose
              });
            }
            if (val1 == 'post') {
              if (val2 == 'new') {
                alert('New journal posted successfully');
                this.setState({
                  date: '',
                  auto_reversing_date: '',
                  narration: '',
                  auto_reversing_date: '',
                  selected_currency: '',
                  exchange_rate: '',
                  debitSubTotalHome: '',
                  taxTotalDebitHome: '',
                  grandTotalDebitHome: '',
                  debitSubTotal: '',
                  taxTotalDebit: '',
                  grandTotalDebit: '',
                  creditSubTotalHome: '',
                  taxTotalCreditHome: '',
                  grandTotalCreditHome: '',
                  creditSubTotal: '',
                  taxTotalCredit: '',
                  grandTotalCredit: '',
                  old_password: '',
                  data_table: [],
                });
                this.addRow(2);
                this.newJournalNextFunc();
                alert(response.message);
                // window.open("/new_journal")
              } else {
                alert(response.message);
                // alert("New journal posted successfully")
                // this.props.history.push("/manual_journal")
              }
            }
          } else if (response.status === 3) {
            jQuery('#lock_date').modal('show');
            this.setState({ message_to_display: response.message });
          } else if (response.status === 4) {
            //  alert(response.message)
            window.jQuery('#asking_password_for_delete').modal('show');
            this.setState({ deleteMsg: true });
          } else {
            this.setState({ loading: false });
            alert(response.message);
          }
        });
        // } else {
        //   alert("Credit & Debit Grand total amount should be Equal")
        // }
      } else {
        this.setState({ errorMsg: true, tableerror: true });
        setTimeout(() => {
          this.setState({ errorMsg: false });
        }, 5000);
      }
    } else {
      this.setState({ loading: true });
      FetchAllApi.post_new_journal(input, (err, response) => {
        if (response.status === 1) {
          this.setState({ loading: false });
          if (val1 == 'draft') {
            if (val2 == 'new') {
              this.setState({
                date: '',
                auto_reversing_date: '',
                narration: '',
                auto_reversing_date: '',
                selected_currency: '',
                exchange_rate: '',
                debitSubTotalHome: '',
                taxTotalDebitHome: '',
                grandTotalDebitHome: '',
                debitSubTotal: '',
                taxTotalDebit: '',
                grandTotalDebit: '',
                creditSubTotalHome: '',
                taxTotalCreditHome: '',
                grandTotalCreditHome: '',
                creditSubTotal: '',
                taxTotalCredit: '',
                grandTotalCredit: '',
                old_password: '',
                data_table: [],
              });
              this.addRow(2);
              this.newJournalNextFunc();
              alert('Draft saved successfully');
              // window.open("/new_journal")
            } else {
              alert('Draft saved successfully');
              // this.props.history.push("/manual_journal")
            }
          } else {
            alert('Journal saved successfully');
            this.props.history.push('/manual_journal');
          }
        } else {
          this.setState({ loading: false });
          alert(response.message);
        }
      });
    }
  };
  resetForm = () => {
    this.setState({
      radio: '',
      new_name: '',
    });
  };

  render() {
    console.log('CHECK_TABLEDATA', this.state.data_table);
    return (
      <div>
        {this.state.loading ? <div class="loading_spinner">Loading&#8230;</div> : ''}
        <div className="container-fluid">
          {/* header Starts here */}
          <UserTopbar logoutSubmit={e => this.logoutLink()} />
          {/* header Ends here */}
          {/* <div class="title-sec col-md-12 col-xs-12">
        <h3>Subscribers</h3>
    </div> */}
          {/* user-content Starts here */}
          <section className="user-content row pad-b-no">
            <Sidebar />
            <div className="user-cont-right">
              <div className="title-sec col-md-12 col-xs-12">
                <h3>
                  <a href="/manual_journal" className="back">
                    <img src="images/back-arrow-blue.svg" />
                  </a>
                  New Manual Journal
                </h3>
              </div>
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="alert alert-danger alert-dismissible custom-dismissible">
                        <a href="#" className="close" data-dismiss="alert" aria-label="close">
                          ×
                        </a>
                        <div className="dflex">
                          <span className="alert-icon">
                            <img src="images/caution-icon.svg" alt="icon" />
                          </span>
                          <div>
                            <span className="cont fs-13">
                              We recommend that only your accountant or bookkeeper create journals, unless you have experience
                              managing your general ledger.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form className="custom-form invoice-form col-md-12 pad-no">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Narration<span className="astrick">*</span>
                          </label>
                          <textarea
                            className="form-control narration"
                            defaultValue={''}
                            name="narration"
                            value={this.state.narration}
                            onChange={this.narrationChange}
                          />
                        </div>
                        <div className="form-group flex-col">
                          <label
                            className="custom-checkbox small"
                            style={{ display: 'inline-block', width: '312px', marginRight: '10px' }}
                          >
                            <input
                              type="checkbox"
                              name="all"
                              checked={this.state.check}
                              onChange={e => {
                                this.checkChange(e.target.checked);
                              }}
                            />
                            Default narration to journal line description
                            <span className="checkmark" />
                          </label>
                          <label
                            className="custom-checkbox small"
                            style={{ display: 'inline-block', width: '210px', marginRight: '10px' }}
                          >
                            <input
                              type="checkbox"
                              name="is_home_currency_adjustment"
                              checked={this.state.is_home_currency_adjustment}
                              onChange={e => {
                                this.checkAdjustment(e.target.checked);
                              }}
                            />
                            Home currency adjustment
                            <span className="checkmark" />
                          </label>
                          {/* <label className="custom-checkbox small">
                            <input type="checkbox" name="all" defaultChecked="checked" />Show journal on cash basis reports
                            <span className="checkmark" />
                          </label> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Journal Entry No#<span className="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="MJ-0032"
                            name="entry_no"
                            value={this.state.entry_no}
                            onChange={this.changeValue}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Date<span className="astrick">*</span>
                          </label>
                          <div className="ff-ac">
                            <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                id="date"
                                name="date"
                                value={this.state.date}
                                onBlur={event => {
                                  let value = event.target.value;
                                  setTimeout(() => {
                                    this.setDate(value);
                                  }, 500);
                                }}
                              />
                              <div className="input-group-addon">
                                <img src="images/calendar-icon.svg" alt="icon" onClick={() => jQuery('#date').focus()} />
                              </div>
                            </div>
                            {this.state.date && (
                              <span className="close-btn">
                                <img
                                  className="img-responsive"
                                  src="../../images/close-icon-red.svg"
                                  alt="icon"
                                  onClick={() => this.deleteSetDate()}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Auto Reversing Date (Optional)</label>
                          <div className="ff-ac">
                            <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                name="auto_revesing_date"
                                id="revise_date"
                                value={this.state.auto_reversing_date}
                                onBlur={event => {
                                  let value = event.target.value;
                                  setTimeout(() => {
                                    this.reviceDateFunc(value);
                                  }, 500);
                                }}
                              />
                              <div className="input-group-addon">
                                <img src="images/calendar-icon.svg" alt="icon" onClick={() => jQuery('#revise_date').focus()} />
                              </div>
                            </div>
                            {this.state.auto_reversing_date && (
                              <span className="close-btn">
                                <img
                                  className="img-responsive"
                                  src="../../images/close-icon-red.svg"
                                  alt="icon"
                                  onClick={() => this.deleteReviceDateFunc()}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            Currency<span className="astrick">*</span>
                          </label>
                          <select
                            type="text"
                            className="selectpicker form-control hh "
                            data-live-search="true"
                            defaultValue="MJ-0032"
                            name="selected_currency"
                            value={this.state.selected_currency}
                            onChange={this.currencyChange}
                            disabled={this.state.is_home_currency_adjustment == 1 ? true : false}
                          >
                            <option value="">choose...</option>
                            {this.state.currency_list.map((amm, idx) => {
                              return <option value={amm}>{amm}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mar-top">
                          <label className="custom-checkbox small">
                            <input type="checkbox" name="all" onClick={this.taxAdd} />
                            Including Tax
                            <span className="checkmark" />
                          </label>
                          <div className="table-responsive col-md-12">
                            <table className="invoice-item-table">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>No</th>
                                  <th>Description</th>
                                  <th>Name</th>
                                  <th>Job</th>
                                  <th>Category</th>
                                  <th>Tax</th>
                                  <th>Type of Tax</th>
                                  <th className="text-right">Debit {this.state.selected_currency}</th>
                                  <th className="text-right">Credit {this.state.selected_currency}</th>
                                </tr>
                              </thead>
                              <tbody className="ui-sortable" ref={this.sortableRef}>
                                {this.state.data_table.map((row, idx) => {
                                  // console.log("Row data at index", idx, row);
                                  return (
                                    <NewJournalTableRow
                                      row={row}
                                      idx={idx}
                                      categoryChange={this.categoryChange}
                                      changeCredit={this.changeCredit}
                                      changeDebit={this.changeDebit}
                                      taxClick={this.taxClick}
                                      changeJob={this.changeJob}
                                      changeMemo={this.changeMemo}
                                      changeName={this.changeName}
                                      copyRow={this.copyRow}
                                      deleteRow={this.deleteRow}
                                      default_category_list={this.state.default_category_list}
                                      jobList={this.state.jobList}
                                      gst_list={this.state.gst_list}
                                      ioTaxTypeChange={this.ioTaxTypeChange}
                                      namelist={this.state.namelist}
                                      key={idx + 'kdd'}
                                    />
                                  );
                                })}
                              </tbody>
                            </table>

                            <div className="form-group col-md-12 mar-b-no">
                              <a
                                href="javascript:;"
                                className="add-input"
                                onClick={() => {
                                  this.addRow(1);
                                }}
                              >
                                ADD ROW
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mar-top col-md-12 col-xs-12 total-table">
                          <table className="pull-right">
                            <thead>
                              <tr>
                                <th>&nbsp;</th>
                                <th className="text-center">
                                  Foreign Currency Debit
                                  <br />
                                  {/* ({this.state.currency_customer}) */}({this.state.selected_currency})
                                </th>

                                <th className="text-center">
                                  Foreign Currency Credit
                                  <br />
                                  {/* ({this.state.currency_customer}) */}({this.state.selected_currency})
                                </th>
                                <th className="text-center">
                                  Home Currency Debit
                                  <br />({this.state.clientHomeCurrency})
                                </th>
                                <th className="text-center">
                                  Home Currency Credit
                                  <br />({this.state.clientHomeCurrency})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-right">Sub Total</td>
                                <td className="text-center">
                                  <Comma value={this.state.is_home_currency_adjustment == true ? 0 : this.state.debitSubTotal} />
                                </td>
                                <td className="text-center">
                                  <Comma value={this.state.is_home_currency_adjustment == true ? 0 : this.state.creditSubTotal} />
                                </td>
                                <td className="text-center">
                                  <Comma value={this.state.debitSubTotalHome} />
                                </td>
                                <td className="text-center">
                                  <Comma value={this.state.creditSubTotalHome} />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-right">Tax</td>
                                <td className="text-center">
                                  <Comma value={this.state.is_home_currency_adjustment == true ? 0 : this.state.taxTotalDebit} />
                                </td>
                                <td className="text-center">
                                  <Comma value={this.state.is_home_currency_adjustment == true ? 0 : this.state.taxTotalCredit} />
                                </td>
                                <td className="text-center">
                                  <Comma value={this.state.taxTotalDebitHome} />
                                </td>
                                <td className="text-center">
                                  <Comma value={this.state.taxTotalCreditHome} />
                                </td>
                              </tr>

                              <tr>
                                <td className="text-right">Grand Total</td>
                                <td className="text-center">
                                  <Comma
                                    value={this.state.is_home_currency_adjustment == true ? 0 : this.state.grandTotalDebit}
                                  />
                                </td>
                                <td className="text-center">
                                  <Comma
                                    value={this.state.is_home_currency_adjustment == true ? 0 : this.state.grandTotalCredit}
                                  />
                                </td>
                                <td className="text-center">
                                  <Comma value={this.state.grandTotalDebitHome} />
                                </td>
                                <td className="text-center">
                                  <Comma value={this.state.grandTotalCreditHome} />
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="text-right">Sub Total</td>
                                <td className="text-center"><Comma value={this.state.debitSubTotal}/>{parseFloat(this.state.debitSubTotal).toFixed(2)}</td>
                                <td className="text-center" ><Comma value={this.state.creditSubTotal}/>{parseFloat(this.state.creditSubTotal).toFixed(2)}</td>
                                <td className="text-center"><Comma value={this.state.debitSubTotalHome}/>{parseFloat(this.state.debitSubTotalHome).toFixed(2)}</td>
                                <td className="text-center"><Comma value={this.state.creditSubTotalHome}/>{parseFloat(this.state.creditSubTotalHome).toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td className="text-right">Tax</td>
                                <td className="text-center"><Comma value={this.state.taxTotalDebit}/>{parseFloat(this.state.taxTotalDebit).toFixed(2)}</td>
                                <td className="text-center"><Comma value={this.state.taxTotalCredit}/>{parseFloat(this.state.taxTotalCredit).toFixed(2)}</td>
                                <td className="text-center"><Comma value={this.state.taxTotalDebitHome}/>{parseFloat(this.state.taxTotalDebitHome).toFixed(2)}</td>
                                <td className="text-center"><Comma value={this.state.taxTotalCreditHome}/>{parseFloat(this.state.taxTotalCreditHome).toFixed(2)}</td>
                              </tr>

                              <tr>
                                <td className="text-right">Grand Total</td>
                                <td className="text-center"><Comma value={this.state.grandTotalDebit}/>{parseFloat(this.state.grandTotalDebit).toFixed(2)}</td>
                                <td className="text-center"><Comma value={this.state.grandTotalCredit}/>{parseFloat(this.state.grandTotalCredit).toFixed(2)}</td>
                                <td className="text-center"><Comma value={this.state.grandTotalDebitHome}/>{parseFloat(this.state.grandTotalDebitHome).toFixed(2)}</td>
                                <td className="text-center"><Comma value={this.state.grandTotalCreditHome}/>{parseFloat(this.state.grandTotalCreditHome).toFixed(2)}</td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-12 total-row" id="uss">
                          <div className="row">
                            <div className="form-group exchange-col col-md-5 col-xs-12">
                              <label className="fw-sbold">Exchange Rate 1 {this.state.selected_currency}</label>
                              <div>
                                <input
                                  type="text"
                                  name="exchange_rate"
                                  className="form-control"
                                  id="Exchange"
                                  required
                                  value={this.state.exchange_rate}
                                  autoComplete="off"
                                  onChange={this.changeValue}
                                />{' '}
                                <span className="label">{this.state.clientHomeCurrency}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          {/* user-content Ends here */}
          {/* pf-btm-wrap Starts here */}
          <div className="pf-btm-wrap bg-sticky ">
            <button
              className="btn btn-empty ico"
              // onClick={this.convertHtmlToPdf.bind(this)}
              type="button"
              onClick={() => window.jQuery('#attachment_modal').modal('show')}
            >
              <img src="images/attach-icon.svg" alt="icon" />
              Attachment <span className="blue-txt">{this.state.list_of_attachments.length}</span>
            </button>
            <div className="col-md-12 text-right pad-no ">
              <button
                className="btn btn-lightgray mar-rgt-5 btn-align"
                type="button"
                onClick={() => this.props.history.push('/manual_journal')}
              >
                Close
              </button>
              {/* <button disabled={!this.state.journal_id} className="btn btn-green mar-rgt-5 btn-align" type='button'onClick={() => { this.makevoid() }}>Void</button> */}

              {this.state.journal_id && this.state.status != 2 ? (
                <>
                  <button className="btn btn-green dropdown-toggle btn useDRP mar-rgt-5" type="button" data-toggle="dropdown">
                    Action
                    <span className="caret" />
                  </button>
                  <ul className="dropdown-menu category">
                    <li>
                      <a
                        onClick={() => {
                          this.makevoid();
                        }}
                      >
                        Void
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          this.createCopy();
                        }}
                      >
                        Create a copy
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                ''
              )}

              {!this.state.journal_id || this.state.status == 2 ? (
                <div className="dib">
                  <div className="dropdown menu-item new-cus  mar-rgt-5">
                    <button
                      className="btn btn-yellow btn-align dropdown-toggle btn-arrow"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Save as Draft
                      <span className="caret" />
                    </button>
                    <ul className="dropdown-menu align-right">
                      <li>
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.saveClick('draft', 'no');
                          }}
                        >
                          Save as Draft
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.saveClick('draft', 'new');
                          }}
                        >
                          Save &amp; Add New
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="dib">
                <div className="dropdown menu-item new-cus  mar-rgt-5">
                  <button
                    className="btn btn-green btn-align dropdown-toggle btn-arrow"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Post
                    <span className="caret" />
                  </button>
                  <ul className="dropdown-menu align-right">
                    <li>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.saveClick('post', 'no');
                        }}
                      >
                        Post
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.saveClick('post', 'new');
                        }}
                      >
                        Post &amp; Add New
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="btn-group mar-rgt-5">
                <button type="button" className="btn btn-yellow " onClick={() => { this.saveClick("draft", "no") }}>Save as Draft</button>
                <button type="button" className="btn btn-yellow dropdown-toggle" data-toggle="dropdown">
                  <span className="caret" />
                </button>
                <ul className="dropdown-menu" >
                  <li><a href="javascript:;" onClick={() => { this.saveClick("draft", "no") }}>Save as Draft</a></li>
                  <li><a href="javascript:;" onClick={() => { this.saveClick("draft", "new") }}>Save &amp; Add New</a></li>
                </ul>
              </div>
              <div className="btn-group mar-rgt-5">
                <button type="button" className="btn btn-green " onClick={() => { this.saveClick("post", "no") }}>Post</button>
                <button type="button" className="btn btn-green dropdown-toggle" data-toggle="dropdown">
                  <span className="caret" />
                </button>
                <ul className="dropdown-menu" >
                  <li><a href="javascript:;" onClick={() => { this.saveClick("post", "no") }}>Post</a></li>
                  <li><a href="javascript:;" onClick={() => { this.saveClick("post", "new") }}>Post &amp; Add New</a></li>
                </ul>
              </div> */}
            </div>
            {this.state.errorMsg == true ? (
              <div className="alert alert-card warning alert-dismissible fade in ">
                <a
                  href="#"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={() => {
                    this.setState({ error: false });
                  }}
                >
                  ×
                </a>
                <div className="img-wrap">
                  <img className="img-responsive" src="images/alert-warning.svg" alt="icon" />
                </div>
                <div className="alert-cont">
                  <strong className="title">Mandatory fields must be filled!</strong>
                </div>
              </div>
            ) : null}
          </div>

          {/* pf-btm-wrap Ends here */}
        </div>
        {/* for lock date modal */}
        <div>
          <div class="modal fade in" id="lock_date" role="dialog" style={{ paddingLeft: 15 }}>
            <div class="modal-dialog modal-md" style={{ width: 440 }}>
              <button
                type="button"
                class="close hidden-xs"
                data-dismiss="modal"
                onClick={() => {
                  jQuery('#lock_date').modal('hide');
                  this.setState({ loading: false });
                }}
              >
                <img class="img-responsive" src="../../images/close-red.svg" alt="icon" />
              </button>
              <div class="modal-content">
                <div class="modal-body text-center success-modal">
                  <div class="pop-icon img-size">{/* {<img src="../../images/delete-icon.svg" alt="icon" />} */}</div>

                  <h3>Are you sure?</h3>

                  <p class="fw-500">{this.state.message_to_display}</p>
                  <button
                    className="btn btn-lightgray btn-align"
                    data-dismiss="modal"
                    onClick={() => {
                      jQuery('#lock_date').modal('hide');
                      this.setState({ loading: false });
                    }}
                  >
                    Cancel
                  </button>
                  <span>{'   '}</span>
                  <button
                    class="btn btn-red btn-align"
                    type="button"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#asking_password_for_delete"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade pop-modal" id="asking_password_for_delete" data-backdrop="static">
          <div className="modal-dialog modal-xs custom-modal">
            {/* Modal content onClick={jQuery('.modal-backdrop').removeClass('in')}*/}
            <button
              type="button"
              onClick={() => {
                jQuery('#lock_date').modal('hide');
                this.setState({ loading: false });
              }}
              className="close hidden-xs"
              data-dismiss="modal"
            >
              <img className="img-responsive" src="images/close-red.svg" alt="icon" />
            </button>
            <div className="modal-content">
              <div className="modal-body text-center">
                <h3>Verification</h3>
                <form className="custom-form row column">
                  <div className="form-group col-md-12 col-xs-12">
                    <label>
                      Enter Lock Password<span className="astrick">*</span>
                    </label>
                    <div className="form-group login-eye">
                      <i
                        className="pass-visible"
                        toggle="#password-fieldc"
                        onClick={() => this.setState({ show: !this.state.show })}
                      >
                        <img className="off" src="images/visibility-off.svg" alt="hide" />
                        <img className="on" src="images/visibility.svg" alt="show" />
                      </i>
                      <input
                        type={this.state.show ? 'text' : 'password'}
                        value={this.state.old_password}
                        className="form-control"
                        required="required"
                        autocomplete="off"
                        onChange={e => this.setState({ old_password: e.target.value })}
                      />
                    </div>
                    {this.state.old_password == '' ? (
                      <div style={{ float: 'left' }}>
                        <small style={{ color: 'red' }}>please enter this password field.</small>
                      </div>
                    ) : (
                      ''
                    )}
                    {this.state.old_password != '' && this.state.deleteMsg == true ? (
                      <div style={{ float: 'left' }}>
                        <small style={{ color: 'red' }}>*Incorrect password</small>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no">
                    <button
                      className="btn btn-lightgray mar-rgt-5"
                      data-dismiss="modal"
                      onClick={() => {
                        jQuery('#lock_date').modal('hide');
                        this.setState({ loading: false });
                      }}
                    >
                      Cancel
                    </button>
                    <input
                      type="button"
                      className="btn btn-green mar-rgt-5"
                      data-dismiss="modal"
                      value="Submit"
                      onClick={() => {
                        if (this.state.old_password !== '') {
                          this.saveClick('');
                        }
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade pop-modal" id="pop-modal-1" role="dialog" data-backdrop="static">
          <div className="modal-dialog modal-md custom-modal">
            <button type="button" className="close hidden-xs" data-dismiss="modal" onClick={this.cancel_gst_modal}>
              <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
            </button>
            <div className="modal-content">
              <div className="modal-body text-center">
                <h3>Add New GST</h3>
                <form className="custom-form row">
                  <div className="form-group col-md-12 col-xs-12 pad-no">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <label>
                        Sales Tax Code
                        <span className="astrick">*</span>
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-12">
                      <input
                        type="text"
                        name="taxCode"
                        id="sales_tax_code"
                        autoComplete="off"
                        maxLength="6"
                        className="form-control"
                        onChange={event => this.handleChangeTax(event)}
                        required
                      />

                      <p className="input-info">(Maximum 6 characters)</p>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-xs-12 pad-no">
                    <div className="col-md-4 col-sm-4 col-md-12">
                      <label>
                        Sales Tax Name
                        <span className="astrick">*</span>
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-12">
                      <input
                        type="text"
                        name="taxName"
                        autoComplete="off"
                        id="sales_tax_name"
                        className="form-control"
                        onChange={event => this.handleChangeTax(event)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <label>Tax Type</label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-12">
                      <label className="custom-checkbox radio mar-rgt taxable">
                        <input
                          type="radio"
                          name="tax-item"
                          value="option1"
                          checked={this.state.selectedOption === 'option1'}
                          onChange={this.handleOptionChange}
                        />
                        Taxable
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox radio non-taxable">
                        <input
                          type="radio"
                          name="tax-item"
                          value="option2"
                          checked={this.state.selectedOption === 'option2'}
                          onChange={this.handleOptionChange}
                        />{' '}
                        Non-Taxable/Exempt
                        <span className="checkmark"></span>
                      </label>
                      {this.state.selectedOption === 'option1' ? (
                        <div className="hidden-field col-md-12 col-xs-12">
                          <div className="form-group">
                            <label className="mar-t-no mar-btm">Tax item for purchases & sales</label>
                            <div className="col-md-12">
                              <div className="row">
                                <label className="mar-rgt" style={{ marginTop: 8 }}>
                                  Rate
                                </label>
                                {}
                                <div className="input-group rate-input">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="taxRate"
                                    id="tax"
                                    autoComplete="off"
                                    required
                                    onInput={event => this.handleChange_gst_type(event)}
                                  />
                                  <div className="input-group-btn">
                                    <div className="custom-select-drop dropdown">
                                      <a
                                        aria-expanded="false"
                                        aria-haspopup="true"
                                        role="button"
                                        data-toggle="dropdown"
                                        className="dropdown-toggle btn"
                                        href="javascript:;"
                                      >
                                        <span id="selected">{this.state.selected_rate_type}</span>
                                        <span className="caret"></span>
                                      </a>
                                      <ul className="dropdown-menu">
                                        <li className="active">
                                          <a href="javascript:;" onClick={this.update_rate_type}>
                                            %
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:;" onClick={this.update_rate_fixed}>
                                            Fixed price
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* <div className='mymsg'>{this.state.modal_info_msg}</div> */}
                  <small style={{ color: 'red' }} className="mymsg">
                    {this.state.modal_info_msg}{' '}
                  </small>

                  <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                    {this.state.show_succes ? (
                      <div className="alert alert-success">
                        <strong>Success!</strong> Your new GST is added.
                      </div>
                    ) : (
                      ''
                    )}
                    <button className="btn btn-lightgray mar-rgt-5" data-dismiss="modal" onClick={this.modal_cancel}>
                      Cancel
                    </button>
                    <span>{'   '}</span>
                    <button className="btn btn-green mar-rgt-5" type="submit" onClick={this.add_gst_details}>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* attachment_modal */}
        <div className="modal fade pop-modal" id="attachment_modal" role="dialog" ata-backdrop="static">
          <div className="modal-dialog modal-md custom-modal">
            <button type="button" className="close hidden-xs" data-dismiss="modal">
              <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
            </button>
            <div className="modal-content">
              <div className="modal-body text-center">
                <h3>
                  Attachments <span className="blue-txt">{this.state.list_of_attachments.length}</span>
                </h3>
                <form className="custom-form row">
                  <div className="form-group col-md-12 col-xs-12 pad-no mar-b-no searchscroll">
                    {this.state.list_of_attachments.length > 0 &&
                      this.state.list_of_attachments.map(item => (
                        <div key={item.source_id} className="attachment-preview ">
                          <a
                            href="javascript:;"
                            onClick={() => {
                              var win = window.open(`${item.source_path}`, '_blank');
                              win.focus();
                            }}
                          >
                            {item.source_path.split('/')[5]}
                          </a>
                          <button
                            type="button"
                            className="attachment-cancel"
                            onClick={() => {
                              this.setState({
                                list_of_attachments: this.state.list_of_attachments.filter(x => x.source_id !== item.source_id),
                              });
                            }}
                          >
                            X
                          </button>
                        </div>
                      ))}
                  </div>
                  <button
                    data-dismiss="modal"
                    className="btn btn-green btn-align"
                    type="button"
                    onClick={() => window.jQuery('#upload_doc_modal').modal('show')}
                  >
                    Upload attachment
                  </button>
                  <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                    <button className="btn btn-lightgray btn-align" data-dismiss="modal" type="button">
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* upload_doc_modal */}
        <div className="modal fade pop-modal" id="upload_doc_modal" role="dialog" ata-backdrop="static">
          <div className="modal-dialog modal-md custom-modal">
            <button type="button" className="close hidden-xs" data-dismiss="modal">
              <img className="img-responsive" src="../../images/close-red.svg" alt="icon" />
            </button>
            <div className="modal-content">
              <div className="modal-body text-center">
                <h3>Upload Attachments</h3>
                <form className="custom-form row">
                  <div className="form-group  pad-no mar-b-no">
                    <div className="">
                      <div className="form-group">
                        {/* <label>Upload Attachments<span className="astrick">*</span></label> */}
                        <div className="upload-wizard">
                          <div className="upload-thumb hide">
                            <ul className="list-inline">
                              {this.state.attachment_file.map((file_data, index) => {
                                var file_url = URL.createObjectURL(file_data);
                                var file_name = file_data.name;

                                if (file_data.type === 'application/pdf') {
                                  var blob = file_url;

                                  console.log('blob_url', file_data.name);

                                  //return this.createPdfImg(file_data);

                                  return (
                                    <li className="previewImg">
                                      <div className="thumb-img">
                                        <a
                                          href="javascript:;"
                                          className="del-btn"
                                          onClick={() => {
                                            this.removeImage(file_data.name, index);
                                          }}
                                        >
                                          <img src="./images/close-icon-white.svg" alt="delete" />
                                        </a>
                                        <PDFtoIMG file={blob}>
                                          {({ pages }) => {
                                            console.log('check length', pages);

                                            if (!pages.length) return 'check Loading...';
                                            return pages.map((page, index) =>
                                              index == 0 ? <img key={index} src={page} title={file_name} alt="pdf" /> : '',
                                            );
                                          }}
                                        </PDFtoIMG>
                                      </div>
                                      <span className="file-name">{file_name}</span>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li className="previewImg">
                                      <div className="thumb-img">
                                        <a
                                          href="javascript:;"
                                          className="del-btn"
                                          onClick={() => {
                                            this.removeImage(file_data.name);
                                          }}
                                        >
                                          <img src="./images/close-icon-white.svg" alt="delete" />
                                        </a>

                                        <img
                                          src={
                                            file_data.type === 'application/vnd.ms-excel'
                                              ? '/images/file-icon.svg'
                                              : file_data.type ===
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                              ? '/images/file-icon.svg'
                                              : file_url
                                          }
                                          title={file_data.name}
                                          alt={file_data.name}
                                        />
                                      </div>
                                      <span className="file-name">{file_data.name}</span>
                                    </li>
                                  );
                                }
                              })}

                              <li className="addMore">
                                <input
                                  type="file"
                                  name="imgInp[]"
                                  id="imgInp2"
                                  className="add_img"
                                  multiple
                                  onChange={this.loadFile.bind(this)}
                                  accept="image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                <a href="javascript:;" className="add-more">
                                  <div>
                                    <img src="images/upload-icon.svg" alt="icon" />
                                    Add or Drag here
                                  </div>
                                </a>
                              </li>

                              {/* <li className="addMore">
                                <a href="javascript:;"  className="add-more" primary={false} onClick={() => {this.upload.click();}}>
                                  <div >
                                  <input id="myInput" type="file" ref={ref => (this.upload = ref)} style={{ display: "none" }}
                                      onChange={this.onChangeFile.bind(this)}/>
                                    <label htmlFor="file">{file}</label>

                                    {this.state.file ? (
                                      <PDFtoIMG file={this.state.file}>
                                        {({ pages }) => {
                                          console.log("length", pages);

                                          if (!pages.length)
                                            return "Loading...";
                                          return pages.map((page, index) => (
                                            (index==0)?
                                            <img   key={index} src={page} style={{height:104.9,width:105.9,marginTop: 13,}}  />
                                            
                                            :""
                                          ));
                                        }}
                                      </PDFtoIMG>
                                    ) : (
                                      <div>Add more</div>
                                    )}
                                    <span className="file-name">{fileName}</span>                              
                                  </div>                                  
                                </a>
                              </li> */}
                            </ul>
                          </div>
                          <div className="upload-space">
                            <input
                              type="file"
                              name="imgInp[]"
                              id="imgInp"
                              className="add_img"
                              multiple
                              onChange={this.loadFile.bind(this)}
                              accept="image/*,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                            {/* <img id="output" src="#" alt="your image" width="70" height="50" /> */}
                            <div>
                              <img className="icon" src="images/upload-icon.svg" alt="icon" />
                              <span>.pdf .png .jpg .xls .xlsx</span>
                              <span className="note-txt">
                                Drag and drop your files here
                                <br />
                                or
                                <br />
                                <a href="javascript:;">click here</a>
                              </span>
                            </div>
                          </div>
                        </div>
                        {this.state.is_files_not_selected ? (
                          <div>
                            <small style={{ color: 'red' }}>Please select atleast one file</small>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-12 col-xs-12 btn-sec pad-no mar-b-no">
                    <button className="btn btn-lightgray btn-align" data-dismiss="modal" type="button">
                      Close
                    </button>
                    <span>{'   '}</span>
                    <button className="btn btn-green btn-align" type="button" onClick={() => this.save_attachment()}>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* add new modal */}

        <div className="modal fade pop-modal" id="add-new-modal" role="dialog" data-backdrop="static">
          <div className="modal-dialog modal-xs custom-modal">
            {/* Modal content*/}
            <button type="button" className="close hidden-xs" data-dismiss="modal">
              <img className="img-responsive" src="images/close-red.svg" alt="icon" />
            </button>
            <div className="modal-content">
              <div className="modal-body text-center">
                <h3>Please select one</h3>

                <form className="custom-form row column">
                  <div className="form-group col-md-12 col-xs-12">
                    <div className="check-row">
                      <label className="custom-checkbox radio">
                        <input
                          type="radio"
                          name="mail-address"
                          // onClick={() => {
                          //   this.setState({ radio: 'Customer' });
                          // }}
                          checked={this.state.radio === 'Customer'}
                          onClick={() => {
                            this.setState({ radio: 'Customer' });
                          }}
                        />
                        <span>Customer</span>
                        <span className="checkmark" />
                      </label>
                      <label className="custom-checkbox radio">
                        <input
                          type="radio"
                          name="mail-address"
                          // onClick={() => {
                          //   this.setState({ radio: 'Vendor' });
                          // }}
                          checked={this.state.radio === 'Vendor'}
                          onClick={() => {
                            this.setState({ radio: 'Vendor' });
                          }}
                        />
                        <span>Vendor</span>
                        <span className="checkmark" />
                      </label>
                      <label className="custom-checkbox radio">
                        <input
                          type="radio"
                          name="mail-address"
                          // onClick={() => {
                          //   this.setState({ radio: 'Employee' });
                          // }}
                          checked={this.state.radio === 'Employee'}
                          onClick={() => {
                            this.setState({ radio: 'Employee' });
                          }}
                        />
                        <span>Employee</span>
                        <span className="checkmark" />
                      </label>
                      <label className="custom-checkbox radio">
                        <input
                          type="radio"
                          name="mail-address"
                          checked={this.state.radio === 'Others'}
                          onClick={() => {
                            this.setState({ radio: 'Others' });
                          }}
                        />
                        <span>Others</span>
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Enter Name<span className="astrick">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="entry_no"
                        value={this.state.new_name}
                        onChange={e => this.setState({ new_name: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-12 col-xs-12 mh btn-sec text-center mar-b-no">
                    <button className="btn btn-lightgray mar-rgt-5" data-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-green mar-rgt-5"
                      data-dismiss="modal"
                      onClick={e => {
                        if (this.state.radio === 'Customer') {
                          window.open('/add-new-customer');
                        } else if (this.state.radio === 'Vendor') {
                          window.open('/add_new_vendor');
                        } else if (this.state.radio === 'Employee') {
                          if (this.state.new_name !== '') {
                            this.saveEmp();
                          }
                        } else if (this.state.radio === 'Others') {
                          if (this.state.new_name !== '') {
                            this.saveOthers();
                          }
                        }
                        this.resetForm();
                      }}
                    >
                      OK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* add new modal */}

        {/* Main Wrapper Ends here */}
        {/* Bootstrap Select Picker JS */}
        {/* Scrollbar Js */}
        {/* Bootstrap Datepicker JS */}
        {/* jQueryUI JS */}
        <Category defaultcategorylist_onchange={this.defaultcategorylist_onchange} />
      </div>
    );
  }
}
