import React from 'react';
import LeftSidebar from '../left_sidebar';
import Footer from '../footer';
import FetchAllApi from '../../api_links/fetch_all_api';
import Topbar from '../topbar';
import Comma from '../comma';
import { jsPDF, specialElementHandlers, margins } from 'jspdf';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import config from './../../api_links/api_links';
import jQuery from 'jquery';

//excel export with formula - starts
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
let excelColumnName = require('excel-column-name');
let row_starts_from = 3;

const containerStyle = { width: '100%', height: '100%', display: 'none' };
const gridStyle = { height: '100%', width: '100%' };
//excel export with formula - end

// import 'bootstrap';
// import 'bootstrap-select';

class vendor_balance_detail extends React.Component {
  constructor(props) {
    super(props);
    //const { history } = this.props;
    this.gridRef = React.createRef();

    this.state = {
      show_coulmns_filter: [],
      filtervalue: [],
      loading: true,
      logged_user_id: localStorage.getItem('logged_user_id'),
      logged_client_id: localStorage.getItem('logged_client_id'),
      logged_role_id: localStorage.getItem('logged_role_id'),
      logged_user_name: localStorage.getItem('logged_user_name'),
      logged_user_email: localStorage.getItem('logged_user_email'),
      logged_user_phone: localStorage.getItem('logged_user_phone'),
      logged_user_image: localStorage.getItem('logged_user_image'),
      logged_company_name: localStorage.getItem('logged_company_name'),
      client_date_format: localStorage.getItem('date_format'),

      country_sortname: localStorage.getItem('country_sortname'),
      language_code: localStorage.getItem('language_code'),
      home_currency: localStorage.getItem('home_currency'),

      incorporation_date: localStorage.getItem('incorporation_date'),
      home_currency_symbol: localStorage.getItem('home_currency_symbol'),
      // home_currency: 'SGD',

      selectedAccountIds: '',
      total_revenue: '',
      cost_of_goods_sold: '',
      gross_profit: '',
      net_income: '',
      selectedCurrencies: '',
      reportObject: [],
      numberOfColumns: [],
      dateList: [],
      endDate: '2020-01-31',
      startDate: '2020-01-01',
      start_date: localStorage.getItem('incorporation_date'),
      end_date: moment().add(10, 'years').format('YYYY-MM-DD'),
      dropdown: '',
      show_column: '',
      inbox_list: [],
      response_stus: 0,
      response_msg: 'No data found',
      item_details: '',
      item_file_path: '',
      waiting_re: [],
      re_assigned: [],
      Subcat_array: [],
      response: '',
      coulmns_head: [],
      final_total: '',
      options: '',
      From: '',
      To: '',
      filter_options: { condition: '', value: '', from: '', to: '' },
      valueAmount: '',
      valueAmount_type: '',
      disable: false,
      selectedFil: 0,
      // currencies: [],
      currencies: config.all_currency_list,
      vendorNames: [],

      selected_vendor_ids: [],
      changefromDate_duedate: '',
      todate_duedate: '',
      data: [
        {
          id: 1,
          heading_name: 'Type',
          clsname: 'Type',
        },

        {
          id: 3,
          heading_name: 'Customer Id',
          clsname: 'customer_id',
        },
        {
          id: 4,
          heading_name: 'Vendor email',
          clsname: 'Customeremail',
        },
        {
          id: 5,
          heading_name: 'Vendor address',
          clsname: 'Companyaddress',
        },
        {
          id: 6,
          heading_name: 'Vendor name',
          clsname: 'Companyname',
        },
        {
          id: 7,
          heading_name: 'Invoice date',
          clsname: 'Invoicedate',
        },
        // {
        //   id: 8,
        //   heading_name: 'Customer name',
        //   clsname: 'Customername'
        // },
        // {
        //   id: 9,
        //   heading_name: 'Job name',
        //   clsname: 'jobname'
        // },
        // {
        //   id: 10,
        //   heading_name: 'Customer address',
        //   clsname: 'customer_address'
        // },
        {
          id: 11,
          heading_name: 'Vendor phone',
          clsname: 'customer_phone',
        },
        {
          id: 12,
          heading_name: 'Memo',
          clsname: 'Memo',
        },
        // {
        //   id: 13,
        //   heading_name: 'Item total home currency',
        //   clsname: 'Itemtotalhomecurrency'
        // },
        // {
        //   id: 14,
        //   heading_name: 'Tax amount home currency',
        //   clsname: 'Taxamounthomecurrency'
        // },
        // {
        //   id: 15,
        //   heading_name: 'Grand total home currency',
        //   clsname: 'Grandtotalhomecurrency'
        // },
        {
          id: 16,
          heading_name: 'Foreign currency',
          clsname: 'Foreigncurrency',
        },
        {
          id: 17,
          heading_name: 'Exchange rate',
          clsname: 'Exchangerate',
        },
        {
          id: 18,
          heading_name: 'Due date',
          clsname: 'Duedate',
        },
        {
          id: 19,
          heading_name: 'Terms',
          clsname: 'Termsconditions',
        },
        {
          id: 20,
          heading_name: 'Invoice number',
          clsname: 'Invoicenumber',
        },
        {
          id: 21,
          heading_name: 'Shipping address',
          clsname: 'Shippingaddress',
        },
        {
          id: 22,
          heading_name: 'Credit',
          clsname: 'credit',
        },
        {
          id: 23,
          heading_name: 'Debit',
          clsname: 'debit',
        },
        {
          id: 24,
          heading_name: 'Open Balance Home currency',
          clsname: 'Grandforeigncurrency',
        },
      ],

      valueAmount_type1: '',
      valueAmount1: '',
      date_start: '',
      date_end: '',
      number_from: '',
      number_to: '',
      memo: '',
      result_array: [],
      all_report_name_id: '',
      open_balance: '',
      Exchange_rate: '',
      F_open: '',
      Address: '',
      website: '',
      mail: '',
      accnum: '',
      phone_number: '',
      vendor_type: [],
      customer_type: [],
      paymentTerms: [],
      selectedTerms: [],
      selectedCustomer_type: [],
      selectedVendor_type: [],
      valueAmount_type2: '',
      valueAmount_type3: '',
      valueAmount2: '',
      valueAmount3: '',

      type: false,
      sort_type: 'asc',
      sortBynames: [],
      selectedName: '',
      filter_key_names: [],
      date_range: 'Custom',
      export_file_name_date_range: 'All',
      is_current_date_report: 1,

      //excel export with formula - starts
      export_formula_column_defs: [],
      defaultColDef: {
        cellClassRules: {
          bold: params => {
            // console.log(this.state.all_account_list, 'params');
            if (params.data['Trans#'] && params.data['Trans#'] !== '') {
              return (
                params.data['Trans#'].toLowerCase().includes('total') ||
                params.data['Trans#'].toLowerCase().includes('other') ||
                this.state.all_account_list.some(
                  e =>
                    e.name &&
                    e.name !== '' &&
                    e.name
                      .split(':')
                      .map(str => str.trim())
                      .some(p => p.toLowerCase() === params.data['Trans#'].toLowerCase()),
                )
                // params.data['Trans#'].toLowerCase().includes('assets')
              );
            } else {
              return false;
            }
          },
        },
        flex: 1,
        minWidth: 100,
        resizable: true,
      },
      excelStyles: [
        {
          id: 'bold',
          font: {
            bold: true,
          },
        },
      ],
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: params => {
          const rowIndex = params.accumulatedRowIndex;
          const valueGetter = params.column.getColDef().valueGetter;
          return !!valueGetter ? `=CONCATENATE(A${rowIndex}, " ", B${rowIndex})` : params.value;
        },
        fileName: 'Balance_sheet.xls',
        sheetName: 'Balance_sheet',
      },
      parent_job_object: {},
      //excel export with formula - end
    };
  }

  //excel export with formula - starts
  onBtExport = () => {
    this.gridRef.current.api.exportDataAsExcel();
  };

  htmlToJson = html => {
    FetchAllApi.html_to_json_converter({ html_content: html }, (err, response) => {
      this.setState(prevState => ({
        defaultExcelExportParams: {
          ...prevState.defaultExcelExportParams,
          fileName: 'Vendor-unpaid-bills-' + this.state.export_file_name_date_range + `.xls`,
          sheetName: this.state.export_file_name_date_range,
        },
      }));

      if (response.status === 1) {
        let columnDefs = [];

        let excel_column_of_table_heading_obj = {};
        let excel_column_of_table_heading_array = [];

        let data = response.data;

        if (this.state.coulmns_head && this.state.coulmns_head.length > 0) {
          // let col_number = 1;
          let col_number = 0;

          let currently_selected_options = jQuery('#myselect option');

          let active_columns_for_export = [];
          for (var i = 0, iLen = currently_selected_options.length; i < iLen; i++) {
            let opt = currently_selected_options[i];

            if (opt.selected) {
              let value = JSON.parse(opt.value);
              //  active_columns_for_export.push(value.b);
              active_columns_for_export.push(value.b + 1);
            }
          }

          // if expand view is enabled means add split columns to excel export
          if (this.state.view == false) {
            active_columns_for_export = active_columns_for_export.concat([14, 19, 27]);
          }

          this.state.coulmns_head.forEach((date, i) => {
            // let actual_col = i + 1;
            let actual_col = i + 2;

            if (active_columns_for_export.indexOf(actual_col) >= 0 || actual_col == 1) {
              col_number = col_number + 1;

              columnDefs.push({
                field: date.heading_name,
              });
            }

            let current_column = excelColumnName.intToExcelCol(col_number);
            excel_column_of_table_heading_obj[date.heading_name] = current_column;
          });
        }

        if (data.length > 1) {
          let current_row = row_starts_from - 1;
          let is_transactions_starts = 0;

          let vendor_currency_object = {};
          let customer_job_currency_object = {};
          let customer_object = {};
          let grand_total_object = {
            sub_category_total_rows: [],
          };

          let date_detailsArray = this.state.dateList;
          data.forEach((d, i) => {
            current_row = current_row + 1;

            if (d['vendor id'] == -1 && d['currency code'] == -1) {
            } else if (d['vendor id'] && !d['currency code'] && d['Trans#'].toLowerCase().includes('total')) {
              grand_total_object['sub_category_total_rows'].push(current_row);
            } else if (d['vendor id'] && d['currency code'] && !d['Trans#'].toLowerCase().includes('total')) {
              d.Debit = this.commaSeperatedValueToNumber(d.Debit);
              d.Credit = this.commaSeperatedValueToNumber(d.Credit);
              d.Amount = this.commaSeperatedValueToNumber(d.Amount);
              d['Open Balance'] = this.commaSeperatedValueToNumber(d['Open Balance']);
              d['Balance'] = this.commaSeperatedValueToNumber(d['Balance']);

              d['Foreign Debit'] = this.commaSeperatedValueToNumber(d['Foreign Debit']);
              d['Foreign Credit'] = this.commaSeperatedValueToNumber(d['Foreign Credit']);
              d['Foreign Amount'] = this.commaSeperatedValueToNumber(d['Foreign Amount']);
              d['Foreign Open Balance'] = this.commaSeperatedValueToNumber(d['Foreign Open Balance']);
              d['Foreign Balance'] = this.commaSeperatedValueToNumber(d['Foreign Balance']);

              let v_id = d['vendor id'];
              let c_code = d['currency code'];

              if (vendor_currency_object[v_id] == undefined) {
                vendor_currency_object[v_id] = {
                  v_id: v_id,
                  sub_category_total_rows: [],
                  currencies: {},
                };
              }

              if (vendor_currency_object[v_id]['currencies'][c_code] == undefined) {
                vendor_currency_object[v_id]['currencies'][c_code] = {
                  c_code: c_code,
                  sub_category_total_rows: [],
                };
              }
              vendor_currency_object[v_id]['currencies'][c_code]['sub_category_total_rows'].push(current_row);
            } else if (d['vendor id'] && d['currency code'] && d['Trans#'].toLowerCase().includes('total')) {
              let v_id = d['vendor id'];
              if (vendor_currency_object[v_id] == undefined) {
                vendor_currency_object[v_id] = {
                  v_id: v_id,
                  sub_category_total_rows: [],
                  currencies: {},
                };
              }
              vendor_currency_object[v_id]['sub_category_total_rows'].push(current_row);
            }

            if (d['Trans#'].toLowerCase().includes('total')) {
              if (
                (d['vendor id'] && d['currency code']) ||
                (d['vendor id'] && !d['currency code']) ||
                (d['vendor id'] == -2 && d['currency code'] == -2)
              ) {
                let c_code = d['currency code'];
                let v_id = d['vendor id'];

                let sub_rows = [];
                if (d['vendor id'] && d['currency code']) {
                  sub_rows =
                    vendor_currency_object[v_id] &&
                    vendor_currency_object[v_id]['currencies'][c_code] &&
                    vendor_currency_object[v_id]['currencies'][c_code]['sub_category_total_rows']
                      ? vendor_currency_object[v_id]['currencies'][c_code]['sub_category_total_rows']
                      : [];
                  if (d['vendor id'] == -2 && d['currency code'] == -2) {
                    sub_rows = grand_total_object['sub_category_total_rows'] ? grand_total_object['sub_category_total_rows'] : [];
                  }
                } else if (d['vendor id'] && !d['currency code']) {
                  sub_rows =
                    vendor_currency_object[v_id] && vendor_currency_object[v_id]['sub_category_total_rows']
                      ? vendor_currency_object[v_id]['sub_category_total_rows']
                      : [];
                }

                let debit_string = `=SUM(`;
                let credit_string = `=SUM(`;
                let amount_string = `=SUM(`;
                let open_balance_string = `=SUM(`;

                let foreign_debit_string = `=SUM(`;
                let foreign_credit_string = `=SUM(`;
                let foreign_amount_string = `=SUM(`;
                let foreign_open_balance_string = `=SUM(`;

                sub_rows.forEach((sr, l) => {
                  debit_string = debit_string + `${excel_column_of_table_heading_obj['Debit']}${sr},`;
                  credit_string = credit_string + `${excel_column_of_table_heading_obj['Credit']}${sr},`;
                  amount_string = amount_string + `${excel_column_of_table_heading_obj['Amount']}${sr},`;
                  open_balance_string = open_balance_string + `${excel_column_of_table_heading_obj['Open Balance']}${sr},`;

                  foreign_debit_string = foreign_debit_string + `${excel_column_of_table_heading_obj['Foreign Debit']}${sr},`;
                  foreign_credit_string = foreign_credit_string + `${excel_column_of_table_heading_obj['Foreign Credit']}${sr},`;
                  foreign_amount_string = foreign_amount_string + `${excel_column_of_table_heading_obj['Foreign Amount']}${sr},`;
                  foreign_open_balance_string =
                    foreign_open_balance_string + `${excel_column_of_table_heading_obj['Foreign Open Balance']}${sr},`;
                });

                debit_string = debit_string.substring(0, debit_string.length - 1) + `)`;
                credit_string = credit_string.substring(0, credit_string.length - 1) + `)`;
                amount_string = amount_string.substring(0, amount_string.length - 1) + `)`;
                open_balance_string = open_balance_string.substring(0, open_balance_string.length - 1) + `)`;

                foreign_debit_string = foreign_debit_string.substring(0, foreign_debit_string.length - 1) + `)`;
                foreign_credit_string = foreign_credit_string.substring(0, foreign_credit_string.length - 1) + `)`;
                foreign_amount_string = foreign_amount_string.substring(0, foreign_amount_string.length - 1) + `)`;
                foreign_open_balance_string =
                  foreign_open_balance_string.substring(0, foreign_open_balance_string.length - 1) + `)`;

                d['Debit'] = debit_string;
                d['Credit'] = credit_string;
                d['Amount'] = amount_string;
                d['Open Balance'] = open_balance_string;

                d['Foreign Debit'] = foreign_debit_string;
                d['Foreign Credit'] = foreign_credit_string;
                d['Foreign Amount'] = foreign_amount_string;
                d['Foreign Open Balance'] = foreign_open_balance_string;
              }
            }

            let detailsArray = this.state.dateList;
            if (is_transactions_starts == 1) {
              is_transactions_starts = 0;
            }
          });
        }

        let header_data = [
          {
            'Trans#':
              this.state.date_range == 'All'
                ? 'Date Range: All'
                : moment(this.state.start_date).format('DD MMM YYYY') +
                  ' TO ' +
                  moment(this.state.end_date).format('DD MMM YYYY'),
          },
        ];
        header_data = header_data.concat(data);

        this.setState(
          {
            export_formula_column_defs: columnDefs,
            export_formula_row_data: header_data,
          },
          () => {
            this.onBtExport();
          },
        );
      } else {
        alert('Failed to export excel');
      }
    });
  };

  commaSeperatedValueToNumber = val => {
    try {
      if (val) {
        if (val == '') {
          return val;
        } else {
          if (typeof val == 'string') {
            val = val.replace(/\s/g, '');
            val = val.replace(/,/g, '');
          }
          // return parseFloat(val.replace(/,/g, ''));
          return parseFloat(val);
        }
      } else {
        return 0;
      }
    } catch (exe) {
      console.log(exe);
      return 0;
    }
  };
  //excel export with formula - end

  rename = (obj, curr) => {
    let a = {};
    Object.keys(obj).map(key => {
      let newKey = key.replace(curr, '');
      Object.assign(a, { [newKey]: obj[key] });
    });
    return a;
  };

  customRadioChange4 = x => {
    this.setState({ valueAmount_type4: x }, () => {
      this.callAPIDATA();
    });
  };

  changeText4 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type3,
            value: this.state.valueAmount3,
            from: this.state.From4,
            to: this.state.To4,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  multiSelectedAccount = () => {
    this.setState({ selectedAccountIds: jQuery('#selectedAccountIds').val() }, () => {
      this.callAPIDATA();
    });
  };

  all_account_list = () => {
    let input = {
      client_id: this.state.logged_client_id,
    };
    // alert('hjgh')
    FetchAllApi.all_account_list(input, (err, response) => {
      if (response.status === 1) {
        this.setState({ all_account_list: response.list });
      } else {
      }
    });
  };

  sortingApi = () => {
    if (this.state.selectedName != '') {
      this.callAPIDATA();
    }
  };

  sortByNames = () => {
    let report_id = this.state.all_report_name_id;
    // alert('hjgh')
    FetchAllApi.reportSortbyOptions(report_id, (err, response) => {
      if (response.status === 1) {
        console.log('rty', response);
        this.setState({ sortBynames: response.list });
      } else {
      }
    });
  };

  all_report_name = () => {
    FetchAllApi.all_report_name((err, response) => {
      if (response.status === 1) {
        console.log('nvnvnv', response);
        let report_ids = response.response;
        for (var i = 0; i < report_ids.length; i++) {
          if (report_ids[i].column_name == 'Unpaid_bills_details') {
            this.setState({ all_report_name_id: report_ids[i].report_id }, () => {
              // this.callAPIDATA()
              this.sortByNames();
            });
          }
        }
      } else {
      }
    });
  };

  vendor_type = () => {
    var client_id = this.state.logged_client_id;

    FetchAllApi.vendorTypes(client_id, (err, response) => {
      console.log('Customer list', response);

      if (response.status === 1) {
        this.setState({ vendor_type: response.list });
      } else {
        this.setState({ vendor_type: [] });
      }
    });
  };

  customer_type = () => {
    FetchAllApi.customerTypes((err, response) => {
      console.log('Customer list', response);
      if (response.status === 1) {
        this.setState({ customer_type: response.lists });
      } else {
        this.setState({ customer_type: [] });
      }
    });
  };

  paymentTerms = () => {
    FetchAllApi.payment_terms((err, response) => {
      console.log('Customer list', response);
      if (response.status === 1) {
        this.setState({ paymentTerms: response.lists });
      } else {
        this.setState({ paymentTerms: [] });
      }
    });
  };

  get_vendorNames = () => {
    let client_id = this.state.logged_client_id;
    let filter_id = 2;

    // alert('hjgh')
    FetchAllApi.getVendorNames(client_id, filter_id, (err, response) => {
      if (response.status === 1) {
        this.setState({ vendorNames: response.list });
      } else {
      }
    });
  };
  get_currencies = () => {
    fetch(
      // "https://api.exchangerate-api.com/v4/latest/SGD"
      `https://api.currencylayer.com/live?access_key=${config.api_key}&source=SGD`,
    )
      .then(response => response.json())
      .then(data => {
        let newObj = this.rename(data.quotes, 'SGD');

        const currencyAr = [];
        let first = newObj;
        for (const key in first) {
          currencyAr.push(key);
        }
        // this.setState({ currencies: currencyAr, currency_clone: currencyAr });
      });
  };
  componentWillMount() {
    this.get_currencies();
    this.get_col();
    this.all_report_name();

    if (this.state.logged_user_id === '' || this.state.logged_user_id === null || this.state.logged_user_id === undefined) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate(prevProp, prevState) {
    window.jQuery('.selectpicker').selectpicker('refresh');
    window.jQuery('.input-group.date').datepicker({ format: 'dd/mm/yyyy', autoclose: true });

    if (prevState.all_report_name_id !== this.state.all_report_name_id) {
      this.callAPIDATA();
    }
  }
  componentDidMount() {
    this.all_account_list();

    document.getElementById('sticky-tb-hdr').addEventListener('scroll', function () {
      var translate = 'translate(0,' + this.scrollTop + 'px)';
      if (
        this.querySelector('thead') != null &&
        this.querySelector('thead') != undefined &&
        this.querySelector('thead').style != null
      ) {
        this.querySelector('thead').style.transform = translate;
      }
    });

    this.vendor_type();
    this.customer_type();
    this.paymentTerms();
    this.show_columnslist();
    this.get_vendorNames();
    // jQuery(".custom-select-drop .dropdown-menu a").click(function () {
    //   jQuery(".open.custom-select-drop .dropdown-menu li.active").removeClass(
    //     "active"
    //   );
    //   jQuery(this).parent("li").addClass("active");
    //   jQuery(".open #selected").text(jQuery(this).text());
    // });

    window.jQuery('.input-group.date').datepicker({ format: 'dd/mm/yyyy', autoclose: true });

    jQuery('.snippet').mouseenter(function () {
      jQuery('.snippet').removeClass('active');
      jQuery(this).addClass('active');
    });

    jQuery('.filter-btn').click(function () {
      jQuery(this).css('visibility', 'hidden');
      jQuery('.report-filter').slideDown();
    });

    jQuery('.report-filter .close-btn').click(function () {
      jQuery('.filter-btn').css('visibility', 'visible');
      jQuery('.report-filter').slideUp();
    });

    const urlParams = new URLSearchParams(window.location.search);
    let start = urlParams.get('start');
    let end = urlParams.get('end');
    let range = urlParams.get('range');
    let is_export = urlParams.get('is_export');
    let export_type = urlParams.get('export_type');

    //newly added for all reports open
    let is_from_all_report = urlParams.get('is_from_all_report');
    if (is_from_all_report == 1 && urlParams != null && urlParams != undefined) {
      this.setState(
        {
          start_date: start,
          end_date: end,
          is_export: is_export ? is_export : 0,
          export_type: export_type ? export_type : '',
        },
        () => {
          this.callAPIDATA();
        },
      );

      document.getElementById('fromdate').value = moment(start).format('DD-MM-YYYY');
      document.getElementById('todate').value = moment(end).format('DD-MM-YYYY');
    } else if (
      localStorage.getItem('fiscal_start_year') != '' &&
      localStorage.getItem('fiscal_start_year') != null &&
      localStorage.getItem('fiscal_start_year') != undefined
    ) {
      let start = moment(localStorage.getItem('fiscal_start_year')).format('DD-MM-YYYY');
      let end = moment(localStorage.getItem('fiscal_end_year')).format('DD-MM-YYYY');
      this.setState(
        { start_date: localStorage.getItem('fiscal_start_year'), end_date: localStorage.getItem('fiscal_end_year') },
        () => {
          this.callAPIDATA();
        },
      );
      // document.getElementById("fromdate").value = start;
      document.getElementById('todate').value = end;
    } else {
      this.setState({ date_range: 'All' }, () => {
        this.callAPIDATA();
      });
      this.changedatevalue('All');
    }

    // this.callAPIDATA();
  }

  customRadioChange = x => {
    this.setState({ valueAmount_type: x });
  };

  customRadioChange1 = x => {
    this.setState({ valueAmount_type1: x });
  };
  customRadioChange2 = x => {
    this.setState({ valueAmount_type2: x });
  };
  customRadioChange3 = x => {
    this.setState({ valueAmount_type3: x });
  };

  selected_item = e => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    let show_columns = optionElement.getAttribute('data-id');
    this.setState({ show_columns: show_columns }, () => {
      this.callAPIDATA();
    });
  };
  pageLink(page_slug) {
    this.props.history.push('/' + page_slug);
  }

  show_coulmn_filter = e => {
    var names = [];
    var result = [];
    console.log('jskdghjkg', result);

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        let value = JSON.parse(opt.value);
        result.push(value.b || opt.text);
        names.push(value.a || opt.text);
        // result.push(opt.value || opt.text);
        jQuery('td:nth-child(' + value.b + '),th:nth-child(' + value.b + ')').show();
      } else {
        let value = JSON.parse(opt.value);
        jQuery('td:nth-child(' + value.b + '),th:nth-child(' + value.b + ')').hide();
      }
    }
    this.setState({ ColscountArray: result }, this.filterFilters(names));
  };

  filterFilters = result => {
    let filter_key_names = [];
    this.state.coulmns_head &&
      this.state.coulmns_head !== undefined &&
      this.state.coulmns_head.map((item, i) => {
        console.log('filter_key_names', item);
        console.log('filter_key_w', result);

        if (result.includes(item.id)) filter_key_names.push(`'${item.filter_key_name}'`);
      });
    console.log('filter_key_wq', filter_key_names);
    this.setState({ filter_key_names: filter_key_names });
    // this.callAPIDATA()
    FetchAllApi.filter_column(
      this.state.all_report_name_id,
      this.state.logged_client_id,
      filter_key_names,
      (errResponse, filtervalue) => {
        console.log('Fijkjlter Result', filtervalue);
        this.setState({ filtervalue: filtervalue });
      },
    );
  };

  break = e1 => {
    console.log('break', e1);
    if (e1.type == 'Sales Invoice' || e1.type == 'Payment') {
      if (e1.type == 'Sales Invoice') {
        var setID = e1.trans;
      } else if (e1.type == 'Payment') {
        var setID = e1.invoice_id + '=' + e1.trans;
      }

      localStorage.setItem('invoice_id', setID);
      localStorage.setItem('job_id', e1.job_id);
      // alert("job id" + e1.job_id);

      var win = window.open('/create_invoice', '_blank');
      win.focus();
    }

    if (e1.type == 'Bill' || e1.type == 'Credit') {
      if (e1.type == 'Bill' || e1.type == 'Credit') {
        let arr = [e1.type, e1.invoice_id];
        console.log('hy', e1.invoice_id);
        localStorage.setItem('vendor_bill', JSON.stringify(arr));
      }

      var win = window.open('/data_tagging/' + e1.list_id + '/' + e1.file_id, '_blank');
      win.focus();
    }

    if (e1.type == 'Bill payment') {
      let arr = [e1.type, e1.invoice_id, e1.payment_id];

      localStorage.setItem('vendor_bill', JSON.stringify(arr));

      // alert(e1.payment_id)
      // this.props.history.push('/data_tagging/' + e1.list_id + '/' + e1.file_id)
      var win = window.open('/data_tagging/' + e1.list_id + '/' + e1.file_id, '_blank');
      win.focus();
    }
    if (e1.type == 'Third Party Payment') {
      if (e1.third_party_payment_from == 'vendor bill payment') {
        let arr = [
          // e1.type,
          // e1.invoice_id
          'Bill payment',
          e1.invoice_id,
          e1.payment_id,
        ];

        localStorage.setItem('vendor_bill', JSON.stringify(arr));

        // alert(e1.payment_id)
        // this.props.history.push('/data_tagging/' + e1.list_id + '/' + e1.file_id)
        var win = window.open('/data_tagging/' + e1.list_id + '/' + e1.file_id, '_blank');
        win.focus();
      } else if (e1.third_party_payment_from == 'customer invoice payment') {
        let arr = [e1.type, e1.invoice_id];
        var setID = e1.invoice_id + '=' + e1.trans;

        localStorage.setItem('invoice_id', setID);
        localStorage.setItem('job_id', e1.job_id);
        localStorage.setItem('vendor_bill', JSON.stringify(arr));

        var win = window.open('/create_invoice', '_blank');
        win.focus();
      }
    } else if (e1.type == 'Vendor credit note' || e1.type == 'Vendor Credit Note') {
      let arr = [e1.type, e1.credit_memo_id];
      localStorage.setItem('vendor_bill', JSON.stringify(arr));
      var win = window.open('/data_tagging/' + e1.list_id + '/' + e1.file_id, '_blank');
      win.focus();
    }

    if (e1.type == 'Deposit') {
      localStorage.setItem('deposit_id', e1.transaction_id);
      var win = window.open('/make_deposit', '_blank');
      win.focus();
    }

    if (e1.type == 'Cheque') {
      localStorage.setItem('cheque_id', e1.transaction_id);
      var win = window.open('/write_cheque', '_blank');
      win.focus();
    }
    if (
      e1.type == 'Batch - Bill' ||
      e1.type == 'Batch - Vendor credit note' ||
      e1.type == 'Batch - Cheque' ||
      e1.type == 'Batch - Deposit'
    ) {
      localStorage.setItem('batchid', e1.batch_transaction_id);
      localStorage.setItem('batch_transaction_row_index', e1.batch_transaction_row_index);
      var win = window.open('/enter_batch_transaction', '_blank');
      win.focus();
    }

    if (e1.type == 'Journal entry') {
      localStorage.setItem('journal_id', e1.transaction_id);
      var win = window.open('/new_journal', '_blank');
      win.focus();
    }
  };

  selectedVendorIds = e => {
    var result = [];

    this.setState({ selected_vendor_ids: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      } else {
      }
    }
  };
  selectedTerms = e => {
    var result = [];

    this.setState({ selectedTerms: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(Number(opt.value) || Number(opt.text));
      } else {
      }
    }
  };
  selectedCustomer_type = e => {
    var result = [];

    this.setState({ selectedCustomer_type: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      } else {
      }
    }
  };
  selectedVendor_type = e => {
    var result = [];

    this.setState({ selectedVendor_type: result }, () => {
      this.callAPIDATA();
    });

    var options = e.target.options;
    var opt;
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      } else {
      }
    }
  };
  selected_filters = e => {
    var result = [];
    var options = e.target.options;
    var opt;
    var j = 0;
    var array = [];
    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result[j] = Number(opt.value);
        j++;
      }
    }
    this.setState({ result_array: result }, () => {
      this.callAPIDATA();
    });

    if (result.length > 0) {
      if (result.includes(1)) {
        this.setState({ selectedFil: 1 });
      }
      if (result.includes(2)) {
        this.setState({ selectedFil: 2 });
      }
      if (result.includes(3)) {
        this.setState({ selectedFil: 3 });
      }
      if (result.includes(5)) {
        this.setState({ selectedFil: 5 });
      }
      if (result.includes(6)) {
        this.setState({ selectedFil: 6 });
      }
      if (result.includes(7)) {
        this.setState({ selectedFil: 7 });
      }
      if (result.includes(8)) {
        this.setState({ selectedFil: 8 });
      }
      if (result.includes(10)) {
        this.setState({ selectedFil: 10 });
      }
      if (result.includes(11)) {
        this.setState({ selectedFil: 11 });
      }

      if (result.includes(13)) {
        this.setState({ selectedFil: 13 });
      }
      if (result.includes(17)) {
        this.setState({ selectedFil: 17 });
      }
      if (result.includes(18)) {
        this.setState({ selectedFil: 18 });
      }
      if (result.includes(24)) {
        this.setState({ selectedFil: 24 });
      }
      if (result.includes(25)) {
        this.setState({ selectedFil: 25 });
      }
      if (result.includes(26)) {
        this.setState({ selectedFil: 26 });
      }
      if (result.includes(27)) {
        this.setState({ selectedFil: 27 });
      }
      if (result.includes(28)) {
        this.setState({ selectedFil: 28 });
      }

      if (result.includes(22)) {
        this.setState({ selectedFil: 22 });
      }
      if (result.includes(23)) {
        this.setState({ selectedFil: 23 });
      }
      if (result.includes(29)) {
        this.setState({ selectedFil: 29 });
      }

      if (result.includes(32)) {
        this.setState({ selectedFil: 32 });
      }

      if (result.includes(50)) {
        this.setState({ selectedFil: 50 });
      }
    } else {
      this.setState({ selectedFil: 0 });
    }
  };
  changeText = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type,
            value: this.state.valueAmount,
            from: this.state.From,
            to: this.state.To,
          },
          filter_options1: {
            condition: this.state.valueAmount_type,
            value: this.state.valueAmount,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText1 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type1,
            value: this.state.valueAmount1,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText2 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type2,
            value: this.state.valueAmount2,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText3 = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: this.state.valueAmount_type3,
            value: this.state.valueAmount3,
            from: this.state.From,
            to: this.state.To,
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  // changeText_Num = (e) => {
  //   this.setState({ [e.target.name]: e.target.value }, () => {
  //     this.setState(
  //       {
  //         filter_options: {
  //           condition: "",
  //           value: "",
  //           from: this.state.number_from,
  //           to: this.state.number_to,
  //         },
  //       },
  //       () => {
  //         this.callAPIDATA();
  //       }
  //     );
  //   });
  // };
  changeText_Num = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.number_from,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_memo = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.memo,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  changeText_open = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.open_balance,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_Exchange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.Exchange_rate,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_Fopen = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.F_open,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_addr = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.Address,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_web = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.website,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_mail = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.mail,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_acc = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.accnum,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };
  changeText_phone = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState(
        {
          filter_options: {
            condition: '',
            value: this.state.phone_number,
            from: '',
            to: '',
          },
        },
        () => {
          this.callAPIDATA();
        },
      );
    });
  };

  logoutLink() {
    localStorage.clear();

    this.props.history.push('/');
  }
  multiSelectedCurrency = cur => {
    //alert(jQuery('#slectedCurrency').val())
    this.setState({ selectedCurrencies: jQuery('#slectedCurrency').val() }, () => {
      this.callAPIDATA();
    });
  };
  changedatevalue(seleteddateformat) {
    var dateresult = moment();
    let from_date, to_date;
    this.setState({ date_range: seleteddateformat }, () => {
      if (seleteddateformat === 'This Month-to-date') {
        from_date = dateresult.startOf('month');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        document.getElementById('todate').value = moment(new Date()).format('DD-MM-YYYY');
        this.state.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Week') {
        from_date = dateresult.startOf('week');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        console.log('startdate', this.state.start_date);
        to_date = dateresult.endOf('week');
        document.getElementById('todate').value = to_date.format('DD-MM-YYYY');
        this.state.end_date = to_date.format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Month') {
        from_date = dateresult.startOf('month');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        to_date = dateresult.endOf('month');
        document.getElementById('todate').value = to_date.format('DD-MM-YYYY');
        this.state.end_date = to_date.format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Week-to-date') {
        from_date = dateresult.startOf('week');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        document.getElementById('todate').value = moment(new Date()).format('DD-MM-YYYY');
        this.state.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Year') {
        from_date = dateresult.startOf('year');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        to_date = dateresult.endOf('year');
        document.getElementById('todate').value = to_date.format('DD-MM-YYYY');
        this.state.end_date = to_date.format('YYYY-MM-DD');
        this.callAPIDATA();
      } else if (seleteddateformat === 'This Year-to-date') {
        from_date = dateresult.startOf('year');
        document.getElementById('fromdate').value = from_date.format('DD-MM-YYYY');
        this.state.start_date = from_date.format('YYYY-MM-DD');
        to_date = moment(new Date()).format('YYYY-MM-DD');
        document.getElementById('todate').value = moment(new Date()).format('DD-MM-YYYY');
        this.state.end_date = to_date;
        this.callAPIDATA();
      } else if (seleteddateformat == 'All') {
        this.setState({ start_date: '1970-01-01', end_date: moment().add(10, 'years').format('YYYY-MM-DD') }, () =>
          this.callAPIDATA(),
        );
        document.getElementById('fromdate').value = '';
        document.getElementById('todate').value = '';
      }
      // let startDate = jQuery('#fromdate').val()
      // let end_date = jQuery('#todate').val()
      // this.setState({ start_date: startDate, end_date: end_date }, () => {
      //   this.callAPIDATA()
      // })
    });
  }
  changefromDate(fromdate) {
    let date = jQuery('#fromdate').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      this.setState({ start_date: date_formated }, () => {
        this.callAPIDATA();
      });
    }

    // this.state.start_date = moment(date).format('YYYY-MM-DD')
  }
  changefromDate1(fromdate) {
    let date = jQuery('#fromdate1').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      this.setState({ date_start: date_formated }, () => {
        this.callAPIDATA();
      });
    }

    // this.state.start_date = moment(date).format('YYYY-MM-DD')
  }
  changefromDate_duedate(fromdate) {
    let date = jQuery('#fromdate_duedate').val();
    console.log('fromdate RTEdsadaasdadasdadad', date);
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      console.log('fromdate RTERE', date_formated);
      this.setState({ changefromDate_duedate: date_formated }, () => {
        this.callAPIDATA();
      });
    }

    // this.state.start_date = moment(date).format('YYYY-MM-DD')
  }

  get_col = () => {
    let report_id = 16;
    FetchAllApi.get_col(this.state.logged_client_id, report_id, (err, response) => {
      if (response.status === 1) {
        var active = [];
        let active_headings = response.response.map(item => {
          if (item.status === 1) {
            active.push(item.heading_name);
          }
        });
        let coulmns_head = response.response;
        let optionList = '';
        if (coulmns_head) {
          var options = coulmns_head.map((item, i) => {
            return <option>{item.heading_name}</option>;
          });
        }

        this.setState({
          selected_vals: active,
          coulmns_head: coulmns_head,
          options: options,
        });
      } else {
        this.setState({
          gst_list: [],
        });
      }
    });
  };

  show_columnslist = () => {
    let report_name = 'balance_sheet';
    FetchAllApi.get_coulmnlist(report_name, (err, response) => {
      if (response.status === 1) {
        this.setState({
          show_coulmns_filter: response.details,
        });
      } else {
        this.setState({
          gst_list: [],
        });
      }
    });
  };

  onChange_filterbysubvalue = val => {
    var sub_columns;
    if (val === 2 || val === 3) {
      sub_columns = [1];
      if (val === 2) {
        if (jQuery('#cadchanges2').prop('checked') == true) this.setState({ cadchange: true });
        else this.setState({ cadchange: false });
      } else {
        if (jQuery('#cadpercentage2').prop('checked') == true) this.setState({ cadpercentage: true });
        else this.setState({ cadpercentage: false });
      }
    } else {
      sub_columns = [4];
      if (val === 5) {
        if (jQuery('#cadchanges1').prop('checked') == true) this.setState({ cadchange: true });
        else this.setState({ cadchange: false });
      } else {
        if (jQuery('#cadpercentage1').prop('checked') == true) this.setState({ cadpercentage: true });
        else this.setState({ cadpercentage: false });
      }
    }
    this.setState({ sub_columns: sub_columns }, () => {
      this.callAPIDATA();
      // alert(this.state.cadchange)
    });
  };
  generate = () => {
    //  var doc = new jsPDF('p', 'pt', 'letter');
    var doc = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: [11.7, 16.5],
    });
    var htmlstring = '';
    var tempVarToCheckPageHeight = 0;
    var pageHeight = 0;
    pageHeight = doc.internal.pageSize.height;
    // specialElementHandlers = {
    //     // element with id of "bypass" - jQuery style selector
    //     '#bypassme': function (element, renderer) {
    //         // true = "handled elsewhere, bypass text extraction"
    //         return true
    //     }
    // };
    // margins = {
    //     top: 150,
    //     bottom: 60,
    //     left: 40,
    //     right: 40,
    //     width: 600
    // };

    //commented on 05-01-2022
    //  var y = 20;
    //  doc.setLineWidth(2);
    //  doc.text(200, y = y + 30, "Vendor Unpaid Bills");
    //  doc.autoTable({
    //      html: '#mytable',
    //      startY: 70,
    //      theme: 'grid',
    //      columnStyles: {
    //          0: {
    //              cellWidth: 180,
    //          },
    //          1: {
    //              cellWidth: 180,
    //          },
    //          2: {
    //              cellWidth: 180,
    //          }
    //      },
    //      styles: {
    //          minCellHeight: 40
    //      }
    //  })

    var y = 20;
    doc.setLineWidth(2);
    doc.text(7, 0.5, 'Vendor Unpaid Bills');

    let date_text =
      this.state.date_range == 'All'
        ? 'Date Range: All'
        : moment(this.state.start_date).format('DD MMM YYYY') + ' TO ' + moment(this.state.end_date).format('DD MMM YYYY');

    if (this.state.date_range == 'All') {
      doc.text(7.2, 0.8, date_text);
    } else {
      doc.text(6.5, 0.8, date_text);
    }
    doc.autoTable({
      html: '#mytable',
      includeHiddenHtml: false,
      // startY: 0.6,
      startY: 0.9,
    });
    doc.save('Vendor-unpaid-bills-' + this.state.export_file_name_date_range + '.pdf');
  };
  onChange_filterby = val => {
    var sub_columns = [val];
    if (val === 1) {
      this.setState({ isChecked2: false, isChecked: true });
    } else {
      this.setState({ isChecked: false, isChecked2: true });
    }
    this.setState({ sub_columns: sub_columns }, () => {
      this.callAPIDATA();
    });

    // FetchAllApi.profit_and_loss_sub_columns(sub_columns, (err, response) => {
    //   if (response.status === 1) {
    //     console.log('jhasgjkghasjk',response)
    //     this.setState({
    //     })
    //   } else {
    //     this.setState({
    //       gst_list: []
    //     })
    //   }
    // })
  };
  changetoDate(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery('#todate').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      if (array != '' && array != undefined) {
        this.setState({ end_date: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
    // this.state.end_date = moment(date).format('YYYY-MM-DD')
    //  alert(moment(todate).format('YYYY-MM-DD'))
  }
  changetoDate1(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery('#todate1').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      if (array != '' && array != undefined) {
        this.setState({ date_end: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
    // this.state.end_date = moment(date).format('YYYY-MM-DD')
    //  alert(moment(todate).format('YYYY-MM-DD'))
  }
  changetoDate_duedate(todate) {
    // alert(jQuery('#todate').val())
    let date = jQuery('#todate_duedate').val();
    if (date != undefined && date != '') {
      var array = date.split('/');
      var date_formated = array[2] + '-' + array[1] + '-' + array[0];
      if (array != '' && array != undefined) {
        this.setState({ todate_duedate: date_formated }, () => {
          this.callAPIDATA();
        });
      }
    }
  }

  callAPIDATA() {
    let filter_id = this.state.result_array;
    let filter_options = {
      1: {
        condition: this.state.valueAmount_type,
        value: this.state.valueAmount,
        from: this.state.From,
        to: this.state.To,
      },
      2: {
        condition: this.state.valueAmount_type1,
        value: this.state.valueAmount1,
        from: '',
        to: '',
      },

      3: {
        condition: '',
        value: '',
        from: this.state.changefromDate_duedate,
        to: this.state.todate_duedate,
      },
      5: {
        condition: '',
        value: [...this.state.selected_vendor_ids],
        from: '',
        to: '',
      },
      6: {
        condition: '',
        value: [...this.state.selectedCurrencies],
        from: '',
        to: '',
      },
      8: {
        condition: '',
        value: '',
        from: this.state.date_start,
        to: this.state.date_end,
      },
      10: {
        condition: '',
        // value: "",
        // from: this.state.number_from,
        value: this.state.number_from,
        from: '',
        to: this.state.number_to,
      },
      11: {
        condition: '',
        value: this.state.memo,
        from: '',
        to: '',
      },
      13: {
        condition: this.state.valueAmount_type2,
        value: this.state.valueAmount2,
        from: '',
        to: '',
      },
      17: {
        condition: '',
        value: this.state.Exchange_rate,
        from: '',
        to: '',
      },
      18: {
        condition: this.state.valueAmount_type3,
        value: this.state.valueAmount3,
        from: '',
        to: '',
      },
      22: {
        condition: '',
        value: [...this.state.selectedTerms],
        from: '',
        to: '',
      },
      23: {
        condition: '',
        value: [...this.state.selectedVendor_type],
        from: '',
        to: '',
      },
      24: {
        condition: '',
        value: this.state.Address,
        from: '',
        to: '',
      },
      25: {
        condition: '',
        value: this.state.website,
        from: '',
        to: '',
      },
      26: {
        condition: '',
        value: this.state.mail,
        from: '',
        to: '',
      },
      27: {
        condition: '',
        value: this.state.accnum,
        from: '',
        to: '',
      },
      28: {
        condition: '',
        value: this.state.phone_number,
        from: '',
        to: '',
      },
      29: {
        condition: '',
        value: [...this.state.selectedCustomer_type],
        from: '',
        to: '',
      },

      50: {
        condition: '',
        value: [...this.state.selectedAccountIds],
        from: '',
        to: '',
      },

      32: {
        condition: this.state.valueAmount_type4,
        value: this.state.valueAmount4,
        from: this.state.From4,
        to: this.state.To4,
      },
    };

    this.setState({ loading: true });
    var start_date = '1970-01-01';
    let { end_date, show_columns, sub_columns } = this.state;
    console.log('start date', start_date.toString());
    console.log('End date', end_date.toString());
    FetchAllApi.filter_column(
      this.state.all_report_name_id,
      this.state.logged_client_id,
      this.state.filter_key_names,
      (errResponse, filtervalue) => {
        console.log('Fijkjlter Result', filtervalue);
        this.setState({ filtervalue: filtervalue });
      },
    );

    if (!this.state.date_range || this.state.date_range == 'All') {
      this.setState({
        export_file_name_date_range: 'All',
      });
    } else if (this.state.start_date && this.state.end_date) {
      let s_date_str = moment(this.state.start_date).format(this.state.client_date_format);
      let e_date_str = moment(this.state.end_date).format(this.state.client_date_format);
      let name = s_date_str + '-TO-' + e_date_str;
      this.setState({
        export_file_name_date_range: name,
      });
    }

    FetchAllApi.unpaid_Bills(
      start_date,
      end_date,
      show_columns,
      this.state.logged_client_id,
      sub_columns,
      filter_id,
      filter_options,
      this.state.selectedName,
      this.state.sort_type,
      this.state.is_current_date_report,

      (err, response) => {
        console.log('BalanceSheet Data', response.details);
        if (response.status === 1) {
          this.setState({ response: response });
          var arrayOfElements = [];
          var numberOfColumns = [];
          var dateList = [];
          console.log('1111111res1', response);
          console.log('1111111res2', arrayOfElements);

          for (let category in response.details) {
            if (response.details.hasOwnProperty(category)) {
              numberOfColumns = response.details[category].date_array.length;
              dateList = response.details[category].date_array;
              arrayOfElements.push(response.details[category]);
            }
          }
          this.setState(
            {
              balance_sheet_data: response.details,
              dateList: dateList,
              bankbalance: response.bank_balance,
              total_assets: response.total_assets,
              total_liabilities: response.total_liabilities,
              total_equity: response.total_equity,
              reportObject: arrayOfElements,
              final_total: response.finalResponse[0].totalamount,
              loading: false,
            },
            () => {
              if (this.state.is_export == 1) {
                setTimeout(() => {
                  this.setState({
                    is_export: false,
                  });
                  if (this.state.export_type == 'pdf') {
                    this.generate();
                  } else if (this.state.export_type == 'excel') {
                    jQuery('#test-table-xls-button').trigger('click');
                  }
                }, 500);
              }
            },
          );
        } else {
          this.setState({ response: [], loading: false, is_export: false });
        }
      },
    );
  }
  changevaluetotals() {
    this.state.changetotal1 = this.state.changetotal;
    this.state.changetotal = 0;
  }
  changevalueper() {
    this.state.changetotal3 = this.state.changetotal2;
    this.state.changetotal2 = 0;
  }
  changevalueperx(value) {
    let x = (parseFloat(this.state.changetotal) - parseFloat(value)) / value / 100;
    if (x || isNaN(x)) x = 0;
    this.state.changetotal2 = x.toFixed(2) + ' %';
  }
  changevaluetotalsx(value) {
    this.state.changetotal = parseInt(this.state.changetotal) - parseInt(value);
  }

  sub_categories = sub_categories => {
    return sub_categories.map((i2, b) => {
      console.log('zz', i2);
      return (
        <React.Fragment>
          <tr className="item-step1 sub-title">
            {this.state.coulmns_head.map((x, y) => {
              if (y === 0) {
                return (
                  <td
                    className={x.clsname}
                    style={{
                      // position: "sticky",
                      // left: "0.25rem",
                      backgroundColor: '#EFEFFF',
                    }}
                  >
                    <div>{i2.currency_name}</div>
                  </td>
                );
              } else {
                return (
                  <td className={x.clsname}>
                    <div></div>
                  </td>
                );
              }
            })}{' '}
            {/* excel export formula */}
            <td className="hide-table-column">
              <div>-1</div>
            </td>
            <td className="hide-table-column">
              <div>-1</div>
            </td>
            {/* excel export formula end */}
          </tr>

          {this.renderItemlist(i2.itemlist)}

          <tr className="item-step1 title1 ">
            <td>
              <span>Total {'' + i2.currency_name}</span>
            </td>

            <td className="">
              <span className="text-right"></span>
            </td>
            <td className="">
              <span className="text-right"></span>
            </td>
            <td className="">
              <span className="text-right"></span>
            </td>
            <td className="">
              <span className="text-right"></span>
            </td>
            <td className="">
              <span className="text-right"></span>
            </td>
            <td className="">
              <span className="text-right"></span>
            </td>

            <td className="">
              <span className="">
                <Comma value={i2.total_open_balance} />
              </span>
            </td>
            <td className="">
              <span className="">
                <Comma value={i2.total_amount} />
              </span>
            </td>

            <td className="">
              <span className="text-right"></span>
            </td>
            <td className="">
              <span className="text-right"></span>
            </td>

            <td className="">
              <span className="">
                <Comma value={i2.total_open_foreign_balance} />
              </span>
            </td>
            <td className="">
              <span className="">
                <Comma value={i2.total_foreign_amount} />
              </span>
            </td>

            <td className="hide-table-column">
              <div>{i2.vendor_id}</div>
            </td>
            <td className="hide-table-column">
              <div>{i2.currency_name}</div>
            </td>
          </tr>
        </React.Fragment>
      );
    });
  };

  renderItemlist = itemlist => {
    let array = itemlist;
    console.log('coming array', array.length);
    if (array) {
      return array.map((i2, b) => {
        // console.log(
        //   "open_balance_home_currency",
        //   i2.open_balance_home_currency
        // );

        // alert(b)
        return (
          <React.Fragment>
            <tr
              className="item-step1"
              onDoubleClick={() => {
                this.break(i2);
              }}
            >
              <td className="">
                <span>{i2.transaction_number != '' ? i2.transaction_number : ''}</span>
              </td>
              <td className="">
                <span>{i2.type}</span>
              </td>
              <td className="">
                <span>{i2.lastmodified != '' ? i2.lastmodified : ''}</span>
              </td>
              <td className="">
                <span>{i2.lastmodifiedby != '' ? i2.lastmodifiedby : ''}</span>
              </td>
              <td className="">
                <span>{i2.trans_date != '' ? i2.trans_date : ''}</span>
              </td>
              <td className="">
                <span>{i2.invoice_number}</span>
              </td>
              <td className="">
                <span>{i2.memo}</span>
              </td>
              {/* <td className="">
                <span>{i2.split}</span>
              </td> */}
              <td className="">
                <span>
                  {' '}
                  <Comma value={i2.open_balance_home_currency} />
                </span>
              </td>
              <td className="">
                <span>
                  {' '}
                  <Comma value={i2.grand_total_home_currency} />
                </span>
              </td>
              <td className="">
                <span>{i2.currency}</span>
              </td>
              <td className="">
                <span>{i2.exchange_rate}</span>
              </td>
              <td className="">
                <span>
                  {' '}
                  <Comma value={i2.balance_foreign_currency} />
                </span>
              </td>
              <td className="">
                <span>
                  {' '}
                  <Comma value={i2.grand_total_foreign_currency} />
                </span>
              </td>
              {/* <td className="">
                <span>{i2.open_balance_foreign_currency}</span>
              </td>
              <td className="">
                <span>{i2.foreign_balance}</span>
              </td>
              <td className="">
                <span>{i2.split != "" ? i2.split : ""}</span>
              </td>
              <td className="">
                <span>{i2.aging != "" ? i2.aging : ""}</span>
              </td>
              <td className="">
                <span>{i2.terms != "" ? i2.terms : ""}</span>
              </td>
              <td className="">
                <span>{i2.contact != "" ? i2.contact : ""}</span>
              </td>
              <td className="">
                <span>{i2.postal_code != "" ? i2.postal_code : ""}</span>
              </td>
              <td className="">
                <span>{i2.province != "" ? i2.province : ""}</span>
              </td>
              <td className="">
                <span>{i2.city !== undefined ? i2.city : ""}</span>
              </td>
              <td className="">
                <span>{i2.address}</span>
              </td>
              <td className="">
                <span>{i2.email}</span>
              </td>
              <td className="">
                <span>{i2.phone !== undefined ? i2.phone : ""}</span>
              </td>
              <td className="">
                <span>{i2.vendor_name}</span>
              </td>
              <td className="">
                <span>{i2.fax}</span>
              </td>
              <td className="">
                <span>{i2.type_name}</span>
              </td> */}

              <td className="hide-table-column">
                <span>{i2.vendor_id}</span>
              </td>
              <td className="hide-table-column">
                <span>{i2.foreign_currency}</span>
              </td>
            </tr>
          </React.Fragment>
        );
      });
    }
  };

  renderCreditlist = (creditslist, vendor_name) => {
    let array = creditslist;
    if (array) {
      return array.map((e1, b) => {
        console.log('e1.contact', e1.contact);
        return (
          <React.Fragment>
            <tr
              className="item-step1"
              onDoubleClick={() => {
                this.break(e1);
              }}
            >
              <td className="">
                <span>{e1.transaction_number != '' ? e1.transaction_number : ''}</span>
              </td>
              <td className="">
                <span>{e1.type}</span>
              </td>
              <td className="">
                <span>{e1.lastmodified != '' ? e1.lastmodified : ''}</span>
              </td>
              <td className="">
                <span>{e1.lastmodifiedby != '' ? e1.lastmodifiedby : ''}</span>
              </td>
              <td className="">
                <span>{e1.trans_date != '' ? e1.trans_date : ''}</span>
              </td>
              <td className="">
                <span>{e1.invoice_number}</span>
              </td>
              <td className="">
                <span>{e1.memo}</span>
              </td>
              {/* <td className="">
                <span>{e1.split}</span>
              </td> */}
              <td className="">
                <span>{e1.open_balance_home_currency}</span>
              </td>
              <td className="">
                <span>{e1.grand_total_home_currency}</span>
              </td>
              <td className="">
                <span>{e1.currency}</span>
              </td>
              <td className="">
                <span>{e1.exchange_rate}</span>
              </td>
              <td className="">
                <span>{e1.balance_foreign_currency}</span>
              </td>
              <td className="">
                <span>{e1.grand_total_foreign_currency}</span>
              </td>
            </tr>
          </React.Fragment>
        );
      });
    }
  };

  render() {
    console.log('hhhm', this.state.all_report_name_id);
    if (this.state.coulmns_head) {
      var list = this.state.coulmns_head.map((item, i) => {
        return <option key={i}>{item.heading_name}</option>;
      });
      // window.jQuery('#myselect').selectpicker().val(list)
    }

    let get_file_path,
      dis_file_path = [],
      item_file_path = [],
      attach_file_path,
      options = [],
      page_no = 1,
      items_limit = 10,
      no_items;

    console.log('response_stus', this.state.options);

    if (this.state.item_details.user_image !== '' && this.state.item_details.user_image !== 'null') {
      var item_user_image = this.state.item_details.user_image;
    } else {
      var item_user_image = 'images/user-img-1.png';
    }

    if (this.state.item_file_path !== '' && this.state.item_file_path !== 'null') {
      item_file_path = [];
      var split_file_path = this.state.item_file_path.toString().split(',');
      var split_file_id = this.state.item_file_id.toString().split(',');
      if (split_file_path.length >= 1) {
        for (var i = 0; i < split_file_path.length; i++) {
          var get_file_url = split_file_path[i];
          var split_file_name = split_file_path[i].toString().split('/');
          var arr_reverse = split_file_name.reverse();

          var get_file_name = arr_reverse[0].substring(arr_reverse[0].length - 15, arr_reverse[0].length);

          var get_file_ext = arr_reverse[0].substring(arr_reverse[0].lastIndexOf('.') + 1, arr_reverse[0].length);
          if (get_file_ext === 'pdf') {
            var file_icon = 'images/pdf-icon.png';
          } else {
            var file_icon = 'images/img-icon.png';
          }

          //console.log('pdf_file_link',get_file_url);

          if (get_file_ext === 'pdf') {
            item_file_path.push(
              <div className="attach-item">
                <a
                  onClick={this.dataTaggingFunc.bind(this, this.state.list_id, split_file_id[i])}
                  className="img-wrap"
                  data-id={split_file_id[i]}
                >
                  <iframe
                    src={get_file_url}
                    id="pdf_thumb_viewer"
                    frameborder="0"
                    scrolling="no"
                    width="190"
                    height="190"
                  ></iframe>
                  <span className="go">
                    <img src="../images/next-arrow-white.svg" className="mCS_img_loaded" />
                  </span>
                </a>
                <a
                  onClick={this.dataTaggingFunc.bind(this, this.state.list_id, split_file_id[i])}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={get_file_url}
                  data-id={split_file_id[i]}
                >
                  <span>{get_file_name}</span>
                  <img src="../images/download-icon.svg" alt="Icon" className="mCS_img_loaded" />
                </a>
              </div>,
            );
          } else {
            item_file_path.push(
              <div className="attach-item">
                <a
                  onClick={this.dataTaggingFunc.bind(this, this.state.list_id, split_file_id[i])}
                  className="img-wrap"
                  data-id={split_file_id[i]}
                >
                  <img className="img-responsive mCS_img_loaded" src={get_file_url} alt={get_file_ext} />
                  <span className="go">
                    <img src="../images/next-arrow-white.svg" className="mCS_img_loaded" />
                  </span>
                </a>
                <a
                  onClick={this.dataTaggingFunc.bind(this, this.state.list_id, split_file_id[i])}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={get_file_url}
                  data-id={split_file_id[i]}
                >
                  <span>{get_file_name}</span>
                  <a href={get_file_url} download={get_file_name}>
                    {get_file_name}
                    <img src="../images/download-icon.svg" alt="Icon" className="mCS_img_loaded" />
                  </a>
                </a>
              </div>,
            );
          }
        }
      }
    }

    options.push(<option>ORG-250</option>);

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <LeftSidebar history={this.props.history} pageSubmit={e => this.pageLink(e)} />

            <div className="main-wrap col-md-12 col-xs-12 pad-r-no">
              <div className="top-bar col-md-12 col-xs-12 pad-r-no">
                <div className="nav-brand-res visible-xs">
                  <img className="img-responsive" src="../images/logo-icon.png" alt="LogoIcon" />
                </div>
                <a href="javascript:;" className="back hidden-xs">
                  <img src="../images/back-arrow-blue.svg" />
                </a>
                <ul className="list-unstyled breadcrumb page-title hidden-xs">
                  <li>
                    <a href="javascript:;">Report</a>
                  </li>
                  <li>Vendor Unpaid Bills</li>
                </ul>

                <Topbar history={this.props.history} logoutSubmit={e => this.logoutLink()} />
              </div>

              <div className="content-top col-md-12 col-xs-12">
                <h4 className="fw-sbold mar-t-no">Vendor Unpaid Bills</h4>
                <h5 className="fw-sbold">{/* {moment(new Date()).format("MMM YYYY")} */}</h5>
              </div>

              <div className="main-content col-md-12 col-xs-12">
                <div className="report-setting">
                  <form className="custom-form form-inline">
                    <div className="form-group mar-rgt">
                      <label>Date Range</label>
                      <div className="form-cont">
                        <select
                          id="custom"
                          className="selectpicker form-control hh "
                          data-live-search="true"
                          value={this.state.date_range}
                          onChange={e => this.changedatevalue(e.target.value)}
                          disabled
                        >
                          <option value="All">All</option>
                          <option value="Custom">Custom</option>
                          <option value="This Month-to-date">This Month-to-date</option>
                          <option value="This Week">This Week</option>
                          <option value="This Month">This Month</option>
                          <option value="This Week-to-date">This Week-to-date</option>
                          <option value="This Year">This Year</option>
                          <option value="This Year-to-date">This Year-to-date</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="form-group mar-rgt">
                      <label>From</label>
                      <div
                        className="input-group date mar-t-no"
                        data-date-format="dd/mm/yyyy"
                      >
                        <input
                          type="text"
                          id="fromdate"
                          onBlur={(e) => {
                            let value = e.target.value
                            this.setState({date_range: "Custom"})
                            jQuery("#custom").val("Custom")
                            setTimeout(() => { this.changefromDate(value) }, 500)
                          }}
                          className="form-control"
                          autoComplete="off"
                        />
                        <div className="input-group-addon" onClick={() => jQuery('#fromdate').focus()}>
                          <img src="images/calendar-icon.svg" alt="icon" />
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group mar-rgt">
                      <label>Select Date</label>
                      <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                        <input
                          type="text"
                          id="todate"
                          onBlur={e => {
                            let value = e.target.value;
                            this.setState({ date_range: 'Custom' });
                            jQuery('#custom').val('Custom');
                            setTimeout(() => {
                              this.changetoDate(value);
                            }, 500);
                          }}
                          className="form-control"
                          autoComplete="off"
                        />
                        <div className="input-group-addon" onClick={() => jQuery('#todate').focus()}>
                          <img src="images/calendar-icon.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <a href="javascript:;" className="text-link filter-btn">
                      Advanced
                    </a>
                    <a
                      href="javascript:;"
                      className="fa fa-refresh"
                      onClick={() => {
                        this.callAPIDATA();
                      }}
                    >
                      <img src="images/refresh.svg" style={{ width: '20px', marginLeft: '10px' }} />
                    </a>
                  </form>

                  <div className="pull-right">
                    <div className="dropdown menu-item new-cus">
                      <button className="btn btn-green dropdown-toggle btn-arrow" data-toggle="dropdown" aria-expanded="false">
                        Export
                        <span className="caret" />
                      </button>
                      <ul className="dropdown-menu align-right">
                        <li>
                          <a href="javascript:void(0);" onClick={this.generate}>
                            Export as PDF
                          </a>
                        </li>
                        <li>
                          {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="excel-button"
                    table="mytable"
                    filename={`Vendor-unpaid-bills-`+this.state.export_file_name_date_range}
                    sheet={`Vendor-unpaid-bills-`+this.state.export_file_name_date_range}
                    buttonText="Export as Excel"/> */}

                          {/* excel export with formula - starts */}
                          <a href="javascript:void(0);">
                            <button
                              className="btn btn-success"
                              id="test-table-xls-button"
                              onClick={() => {
                                let table = document.getElementById('mytable');
                                let html = table.outerHTML;
                                this.htmlToJson(html);
                              }}
                            >
                              Export as Excel
                            </button>
                          </a>
                          {/* excel export with formula - end */}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-12 col-xs-12 report-filter">
                    <a href="javascript:;" className="close-btn">
                      <img src="images/icons8-minus.png" />
                    </a>
                    <form className="custom-form">
                      <div className="col-lg-4 col-md-12 pad-l-no">
                        <div className="row">
                          <div className="form-group col-md-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-5 col-md-3">
                                <label className="fw-sbold">Report Type</label>
                              </div>
                              <div className="col-lg-7 col-md-9">
                                <label className="custom-checkbox radio mar-t-no mar-rgt">
                                  <input
                                    type="radio"
                                    name="tax-item"
                                    checked={this.state.is_current_date_report == 1 ? true : false}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        this.setState({ is_current_date_report: 1 }, () => this.callAPIDATA());
                                      }
                                    }}
                                  />{' '}
                                  Current Date
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox radio">
                                  <input
                                    type="radio"
                                    name="tax-item"
                                    checked={this.state.is_current_date_report == 2 ? true : false}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        this.setState({ is_current_date_report: 2 }, () => this.callAPIDATA());
                                      }
                                    }}
                                  />{' '}
                                  Report Date
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-5 col-md-3">
                                <label className="fw-sbold">Show Columns</label>
                              </div>
                              <div className="col-lg-7 col-md-9">
                                <div className="custom-select-drop dropdown">
                                  {this.state.coulmns_head && this.state.coulmns_head !== undefined && (
                                    <select
                                      className="selectpicker"
                                      id="myselect"
                                      multiple
                                      data-live-search="true"
                                      onChange={e => this.show_coulmn_filter(e)}
                                    >
                                      {this.state.coulmns_head &&
                                        this.state.coulmns_head !== undefined &&
                                        this.state.coulmns_head &&
                                        this.state.coulmns_head.map((item, i) => {
                                          let statusSelected = '';
                                          if (item.status === 1) statusSelected = 'selected';
                                          let object = { a: item.id, b: i + 1 };
                                          return (
                                            <option value={JSON.stringify(object)} selected={statusSelected}>
                                              {item.heading_name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-5 col-md-3">
                                <label className="fw-sbold">Filter by</label>
                              </div>
                              <div className="col-lg-7 col-md-9">
                                <div className="custom-select-drop dropdown">
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={e => this.selected_filters(e)}
                                  >
                                    {this.state.filtervalue &&
                                      this.state.filtervalue.name &&
                                      this.state.filtervalue.name.map((item, index) => {
                                        return (
                                          <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                            {item.filter_name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-xs-12 mar-b-no">
                            <div className="row">
                              <div className="col-lg-5 col-md-3">
                                <label className="fw-sbold">Sort By</label>
                              </div>
                              <div className="col-lg-7 col-md-9">
                                <div id="currency_selected">
                                  <select
                                    className="selectpicker form-control"
                                    id="customer_type"
                                    data-live-search="true"
                                    onChange={e => {
                                      this.setState({ selectedName: e.target.value });
                                      setTimeout(() => {
                                        this.callAPIDATA();
                                      }, 500);
                                    }}
                                  >
                                    <option selected={true}>Choose</option>
                                    {this.state.sortBynames &&
                                      this.state.sortBynames.map(item => {
                                        return (
                                          <React.Fragment>
                                            <option value={item.column_key}>{item.name}</option>
                                          </React.Fragment>
                                        );
                                      })}
                                  </select>
                                </div>

                                <div className="sort-by-icon-margin-pl" style={{ float: 'right' }}>
                                  <a
                                    href="#"
                                    onClick={e => {
                                      e.preventDefault();
                                      let a = this.state.sort_type == 'desc' ? 'asc' : 'desc';
                                      this.setState({ sort_type: a, type: !this.state.type });
                                      setTimeout(() => {
                                        if (this.state.selectedName != '') {
                                          this.callAPIDATA();
                                        }
                                      }, 500);
                                    }}
                                  >
                                    {/* {this.state.sort_type == "desc" ? "asc" : "desc"} */}
                                    {this.state.type ? (
                                      <FontAwesomeIcon icon="fas fa-sort-amount-up" />
                                    ) : (
                                      <FontAwesomeIcon icon="fas fa-sort-amount-down" />
                                    )}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-12 pad-r-no">
                        <div className="row"></div>
                      </div>

                      {this.state.selectedFil == 5 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <div className="custom-select-drop dropdown">
                                  <label>Name</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={e => this.selectedVendorIds(e)}
                                  >
                                    {this.state.vendorNames &&
                                      this.state.vendorNames.map((item, index) => {
                                        return (
                                          <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                            {item.vendor_name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedFil === 3 && (
                        <div className="col-lg-4 col-md-12 pad-r-no">
                          <div className="row">
                            <div className="form-group mar-rgt">
                              <label>From</label>
                              <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                                <input
                                  type="text"
                                  id="fromdate_duedate"
                                  onBlur={e => {
                                    let value = e.target.value;
                                    setTimeout(() => {
                                      this.changefromDate_duedate(value);
                                    }, 500);
                                  }}
                                  className="form-control"
                                  autoComplete="off"
                                  style={{ height: '43px' }}
                                />
                                <div className="input-group-addon" onClick={() => jQuery('#fromdate_duedate').focus()}>
                                  <img src="images/calendar-icon.svg" alt="icon" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group mar-rgt">
                              <label>To</label>
                              <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                                <input
                                  type="text"
                                  id="todate_duedate"
                                  onBlur={e => {
                                    let value = e.target.value;
                                    setTimeout(() => {
                                      this.changetoDate_duedate(value);
                                    }, 500);
                                  }}
                                  className="form-control"
                                  autoComplete="off"
                                  style={{ height: '43px' }}
                                />
                                <div className="input-group-addon" onClick={() => jQuery('#todate_duedate').focus()}>
                                  <img src="images/calendar-icon.svg" alt="icon" />
                                </div>
                              </div>
                            </div>
                          </div>{' '}
                        </div>
                      )}

                      {this.state.selectedFil === 8 && (
                        <div className="col-lg-4 col-md-12 pad-r-no">
                          <div className="row">
                            <div className="form-group mar-rgt">
                              <label>From</label>
                              <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                                <input
                                  type="text"
                                  id="fromdate1"
                                  onBlur={e => {
                                    let value = e.target.value;
                                    setTimeout(() => {
                                      this.changefromDate1(value);
                                    }, 500);
                                  }}
                                  className="form-control"
                                  autoComplete="off"
                                  style={{ height: '43px' }}
                                />
                                <div className="input-group-addon" onClick={() => jQuery('#fromdate1').focus()}>
                                  <img src="images/calendar-icon.svg" alt="icon" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group mar-rgt">
                              <label>To</label>
                              <div className="input-group date mar-t-no" data-date-format="dd/mm/yyyy">
                                <input
                                  type="text"
                                  id="todate1"
                                  onBlur={e => {
                                    let value = e.target.value;
                                    setTimeout(() => {
                                      this.changetoDate1(value);
                                    }, 500);
                                  }}
                                  className="form-control"
                                  autoComplete="off"
                                  style={{ height: '43px' }}
                                />
                                <div className="input-group-addon" onClick={() => jQuery('#todate1').focus()}>
                                  <img src="images/calendar-icon.svg" alt="icon" />
                                </div>
                              </div>
                            </div>
                          </div>{' '}
                        </div>
                      )}
                      {this.state.selectedFil == 6 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <div className="custom-select-drop dropdown">
                                  <label>Currency</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    id="slectedCurrency"
                                    onChange={e => {
                                      this.multiSelectedCurrency(e.target.value);
                                    }}
                                  >
                                    {this.state.currencies &&
                                      this.state.currencies.map((item, index) => {
                                        return (
                                          <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                            {item}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 2 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'='}
                                    onChange={e => {
                                      this.customRadioChange1(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'<='}
                                    onChange={e => {
                                      this.customRadioChange1(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'>='}
                                    onChange={e => {
                                      this.customRadioChange1(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'true'}
                                    onChange={e => {
                                      this.customRadioChange1(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount1"
                                    className="form-control"
                                    onChange={this.changeText1}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 1 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'='}
                                    onChange={e => {
                                      this.customRadioChange(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'<='}
                                    onChange={e => {
                                      this.customRadioChange(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'>='}
                                    onChange={e => {
                                      this.customRadioChange(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'true'}
                                    onChange={e => {
                                      this.customRadioChange(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount"
                                    className="form-control"
                                    onChange={this.changeText}
                                    style={{ width: '128px' }}
                                  />
                                </div>

                                <div>
                                  <div>
                                    <label>From</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="From"
                                      className="form-control"
                                      style={{ width: '128px' }}
                                      onChange={this.changeText}
                                    />
                                  </div>
                                  <div>
                                    <label>To</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="To"
                                      onChange={this.changeText}
                                      className="form-control"
                                      style={{ width: '128px' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 10 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <div>
                                  <label>Number</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="number_from"
                                    className="form-control"
                                    style={{ width: '128px' }}
                                    onChange={this.changeText_Num}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <div>
                                  <label>From</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="number_from"
                                    className="form-control"
                                    style={{ width: "128px" }}
                                    onChange={this.changeText_Num}
                                  />
                                </div>
                                <div>
                                  <label>To</label>
                                  <input
                                    type="text"
                                    id="male"
                                    name="number_to"
                                    onChange={this.changeText_Num}
                                    className="form-control"
                                    style={{ width: "128px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      )}
                      {this.state.selectedFil === 11 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Memo</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="memo"
                                  className="form-control"
                                  onChange={this.changeText_memo}
                                  style={{ width: '128px' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 13 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'='}
                                    onChange={e => {
                                      this.customRadioChange2(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'<='}
                                    onChange={e => {
                                      this.customRadioChange2(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'>='}
                                    onChange={e => {
                                      this.customRadioChange2(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'true'}
                                    onChange={e => {
                                      this.customRadioChange2(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount2"
                                    className="form-control"
                                    onChange={this.changeText2}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 17 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Exchange_rate</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="Exchange_rate"
                                  className="form-control"
                                  onChange={this.changeText_Exchange}
                                  style={{ width: '128px' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 18 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'='}
                                    onChange={e => {
                                      this.customRadioChange3(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'<='}
                                    onChange={e => {
                                      this.customRadioChange3(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'>='}
                                    onChange={e => {
                                      this.customRadioChange3(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'true'}
                                    onChange={e => {
                                      this.customRadioChange3(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount3"
                                    className="form-control"
                                    onChange={this.changeText3}
                                    style={{ width: '128px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 24 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Address</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="Address"
                                  className="form-control"
                                  onChange={this.changeText_addr}
                                  style={{ width: '128px' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 25 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>website</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="website"
                                  className="form-control"
                                  onChange={this.changeText_web}
                                  style={{ width: '128px' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 26 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>E-mail</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="mail"
                                  className="form-control"
                                  onChange={this.changeText_mail}
                                  style={{ width: '128px' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 27 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>Acc No</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="accnum"
                                  className="form-control"
                                  onChange={this.changeText_acc}
                                  style={{ width: '128px' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil === 28 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div>
                                <label>phone number</label>
                                <input
                                  type="text"
                                  id="male"
                                  name="phone_number"
                                  className="form-control"
                                  onChange={this.changeText_phone}
                                  style={{ width: '128px' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil == 22 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div style={{ display: 'block' }}>
                                <div className="custom-select-drop dropdown">
                                  <label>payment-terms</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={e => this.selectedTerms(e)}
                                  >
                                    {this.state.paymentTerms &&
                                      this.state.paymentTerms.map((item, index) => {
                                        return (
                                          <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                            {item.terms}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil == 23 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <div className="custom-select-drop dropdown">
                                  <label>vendor type</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={e => this.selectedVendor_type(e)}
                                  >
                                    {this.state.vendor_type &&
                                      this.state.vendor_type.map((item, index) => {
                                        return (
                                          <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedFil == 29 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <div className="custom-select-drop dropdown">
                                  <label>customer type</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    onChange={e => this.selectedCustomer_type(e)}
                                  >
                                    {this.state.customer_type &&
                                      this.state.customer_type.map((item, index) => {
                                        return (
                                          <option key={index} id={item.filter_name} data-id={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedFil == 50 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <div className="custom-select-drop dropdown">
                                  <label>Accounts</label>
                                  <select
                                    className="selectpicker"
                                    multiple
                                    data-live-search="true"
                                    id="selectedAccountIds"
                                    onChange={e => {
                                      this.multiSelectedAccount(e.target.value);
                                    }}
                                  >
                                    {this.state.all_account_list &&
                                      this.state.all_account_list.map((item, index) => {
                                        return (
                                          <option key={index} id={item.name} data-id={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>

                                {this.state.disable && (
                                  <div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedFil === 32 && (
                        <div className="col-lg-4 col-md-12 pad-r-no" style={{ paddingLeft: 55 }} id="hideme">
                          <div className="row">
                            <div className="form-group col-md-12 col-xs-12">
                              <div id={1} style={{ display: 'block' }}>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'='}
                                    onChange={e => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  =
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'<='}
                                    onChange={e => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  &lt;=
                                  <span className="checkmark" />
                                </label>
                                <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={'>='}
                                    onChange={e => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  &gt;=
                                  <span className="checkmark" />
                                </label>
                                {/* <label className="custom-checkbox mar-rgt">
                                  <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    defaultValue={"true"}
                                    onChange={(e) => {
                                      this.customRadioChange4(e.target.value);
                                    }}
                                  />
                                  any
                                  <span className="checkmark" />
                                </label> */}
                                <div>
                                  <input
                                    type="text"
                                    id="male"
                                    name="valueAmount4"
                                    className="form-control"
                                    onChange={this.changeText4}
                                    style={{ width: '128px' }}
                                  />
                                </div>

                                <div>
                                  <div>
                                    <label>From</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="From4"
                                      className="form-control"
                                      style={{ width: '128px' }}
                                      onChange={this.changeText4}
                                    />
                                  </div>
                                  <div>
                                    <label>To</label>
                                    <input
                                      type="text"
                                      id="male"
                                      name="To4"
                                      onChange={this.changeText4}
                                      className="form-control"
                                      style={{ width: '128px' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>

                <div className="report-table col-md-12 col-xs-12 pad-no">
                  <div className="table-responsive" id="sticky-tb-hdr">
                    <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} visible={this.state.loading} />
                    {!this.state.loading && (
                      <table className="table" id="mytable">
                        <thead>
                          <th>{'As of ' + moment(this.state.end_date).format('DD MMM YYYY')}</th>
                          {this.state.coulmns_head &&
                            this.state.coulmns_head.map(i => <th key={i} style={{ width: '100%', background: 'white' }}></th>)}

                          <tr>
                            {this.state.coulmns_head &&
                              this.state.coulmns_head.map((x, y) => {
                                return (
                                  <th className="text-right" className={x.clsname}>
                                    {x.heading_name}
                                    <i className="th-sort">
                                      <img
                                        // src="../images/sort-icon.svg"
                                        src={config.sort_icon_url}
                                        alt="SortIcon"
                                      />
                                    </i>
                                  </th>
                                );
                              })}

                            {/* formual excel export */}
                            <th className="hide-table-column">vendor id</th>
                            <th className="hide-table-column">currency code</th>
                            {/* formula excel export - end */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.response != '' &&
                            this.state.response.finalResponse.map((primaryObj, i) => {
                              if (i != 0) {
                                // console.log("sdjkfhdj",primaryObj.creditslist)
                                var total = primaryObj.total;

                                var vendor_name = primaryObj.vendor_name;

                                var creditslist = primaryObj.creditslist;

                                var itemlist = primaryObj.itemlist;

                                var total_amnt = primaryObj.total;
                                // console.log( "iiii", itemlist.length);
                                if (i !== undefined && (itemlist.length !== 0 || creditslist.length !== 0)) {
                                  return (
                                    <React.Fragment key={i}>
                                      {' '}
                                      <tr className="title-1">
                                        {this.state.coulmns_head.map((x, y) => {
                                          if (y === 0) {
                                            return (
                                              <td
                                                className={x.clsname}
                                                style={{
                                                  // position: "sticky",
                                                  // left: "0.25rem",
                                                  backgroundColor: '#EFEFFF',
                                                }}
                                              >
                                                {vendor_name}
                                              </td>
                                            );
                                          } else {
                                            return <td className={x.clsname}></td>;
                                          }
                                        })}{' '}
                                        <td className="hide-table-column">
                                          <div>-1</div>
                                        </td>
                                        <td className="hide-table-column">
                                          <div>-1</div>
                                        </td>
                                      </tr>
                                      {/* {this.renderCreditlist(
                                          creditslist,
                                          vendor_name
                                        )} */}
                                      {this.sub_categories(primaryObj.sub_categories)}
                                      <tr className="item-step1 title1 bdr-no">
                                        <td>
                                          <span>Total {vendor_name}</span>
                                        </td>
                                        <td className="">
                                          <span className="text-right"></span>
                                        </td>
                                        <td className="">
                                          <span className="text-right"></span>
                                        </td>
                                        <td className="">
                                          <span className="text-right"></span>
                                        </td>
                                        <td className="">
                                          <span className="text-right"></span>
                                        </td>
                                        <td className="">
                                          <span className="text-right"></span>
                                        </td>
                                        <td className="">
                                          <span className="text-right"></span>
                                        </td>

                                        <td className="">
                                          <span className="">
                                            {!isNaN(primaryObj.total_open_balance) && primaryObj.total_open_balance
                                              ? new Intl.NumberFormat(
                                                  this.state.language_code + '-' + this.state.country_sortname,
                                                  { style: 'currency', currency: this.state.home_currency },
                                                )
                                                  .format(primaryObj.total_open_balance)
                                                  .replace(this.state.home_currency_symbol, '')
                                              : 0}
                                            {/* {primaryObj.total_open_balance} */}
                                          </span>
                                        </td>
                                        <td className="">
                                          <span className="">
                                            {new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                              style: 'currency',
                                              currency: this.state.home_currency,
                                            })
                                              .format(primaryObj.total)
                                              .replace(this.state.home_currency_symbol, '')}

                                            {/* {primaryObj.total} */}
                                          </span>
                                        </td>

                                        <td className="">
                                          <span className="text-right"></span>
                                        </td>
                                        <td className="">
                                          <span className="text-right"></span>
                                        </td>

                                        <td className="">
                                          <span className="">
                                            {new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                              style: 'currency',
                                              currency: this.state.home_currency,
                                            })
                                              .format(primaryObj.total_open_foreign_balance)
                                              .replace(this.state.home_currency_symbol, '')}

                                            {/* {primaryObj.total_open_foreign_balance} */}
                                          </span>
                                        </td>
                                        <td className="">
                                          <span className="">
                                            {new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                              style: 'currency',
                                              currency: this.state.home_currency,
                                            })
                                              .format(primaryObj.total_foreign_amount)
                                              .replace(this.state.home_currency_symbol, '')}

                                            {/* {primaryObj.total_foreign_amount} */}
                                          </span>
                                        </td>

                                        {/* <td>
                                            <span>Total </span>
                                          </td>
                                          <td>
                                            <span className='text-right'> 0.00</span>
                                          </td> */}

                                        {/* excel export formula */}
                                        <td className="hide-table-column">
                                          <span>{primaryObj.vendor_id}</span>
                                        </td>
                                        <td className="hide-table-column">
                                          <span></span>
                                        </td>
                                        {/* excel export formula end */}
                                      </tr>
                                    </React.Fragment>
                                  );
                                }
                              }
                            })}

                          <tr className="item-step1 title1 bdr-no" style={{ backgroundColor: 'lightgrey' }}>
                            <td>
                              <span>Total </span>
                            </td>
                            <td className="">
                              <span className="text-right"></span>
                            </td>
                            <td className="">
                              <span className="text-right"></span>
                            </td>
                            <td className="">
                              <span className="text-right"></span>
                            </td>
                            <td className="">
                              <span className="text-right"></span>
                            </td>
                            <td className="">
                              <span className="text-right"></span>
                            </td>
                            <td className="">
                              <span className="text-right"></span>
                            </td>

                            <td className="">
                              <span className="">
                                {console.log(
                                  !isNaN(this.state.response.overall_total_home_open_balance) &&
                                    this.state.response.overall_total_home_open_balance,
                                  this.state.response,
                                )}
                                {!isNaN(this.state.response.overall_total_home_open_balance) &&
                                this.state.response.overall_total_home_open_balance
                                  ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                      style: 'currency',
                                      currency: this.state.home_currency,
                                    })
                                      .format(this.state.response.overall_total_home_open_balance)
                                      .replace(this.state.home_currency_symbol, '')
                                  : 0}

                                {/* {this.state.response.overall_total_home_open_balance} */}
                              </span>
                            </td>
                            <td className="">
                              <span className="">
                                {!isNaN(this.state.response.overall_total_home_amount) &&
                                this.state.response.overall_total_home_amount
                                  ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                      style: 'currency',
                                      currency: this.state.home_currency,
                                    })
                                      .format(this.state.response.overall_total_home_amount)
                                      .replace(this.state.home_currency_symbol, '')
                                  : 0}

                                {/* {this.state.response.overall_total_home_amount} */}
                              </span>
                            </td>

                            <td className="">
                              <span className="text-right"></span>
                            </td>
                            <td className="">
                              <span className="text-right"></span>
                            </td>

                            <td className="">
                              <span className="">
                                {!isNaN(this.state.response.overall_total_foreign_open_balance) &&
                                this.state.response.overall_total_foreign_open_balance
                                  ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                      style: 'currency',
                                      currency: this.state.home_currency,
                                    })
                                      .format(this.state.response.overall_total_foreign_open_balance)
                                      .replace(this.state.home_currency_symbol, '')
                                  : 0}

                                {/* {this.state.response.overall_total_foreign_open_balance} */}
                              </span>
                            </td>
                            <td className="">
                              {/* <span className="">
                                {!isNaN(this.state.response.overall_total_foreign_open_balance) && this.state.response.overall_total_foreign_open_balance ?(new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname,
                                  { style: 'currency', currency: this.state.home_currency }).format(this.state.response.overall_total_foreign_open_balance)).replace(this.state.home_currency_symbol, ''):0}
                              </span> */}
                              <span className="">
                                {!isNaN(this.state.response.overall_total_foreign_amount) &&
                                this.state.response.overall_total_foreign_amount
                                  ? new Intl.NumberFormat(this.state.language_code + '-' + this.state.country_sortname, {
                                      style: 'currency',
                                      currency: this.state.home_currency,
                                    })
                                      .format(this.state.response.overall_total_foreign_amount)
                                      .replace(this.state.home_currency_symbol, '')
                                  : 0}
                              </span>
                            </td>

                            <td className="hide-table-column">
                              <span>-2</span>
                            </td>
                            <td className="hide-table-column">
                              <span>-2</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {/* excel export with formula - starts */}
                    <div style={containerStyle}>
                      <div className="container">
                        <div className="grid-wrapper">
                          <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact
                              ref={this.gridRef}
                              rowData={this.state.export_formula_row_data}
                              columnDefs={this.state.export_formula_column_defs}
                              defaultColDef={this.state.defaultColDef}
                              excelStyles={this.state.excelStyles}
                              defaultExcelExportParams={this.state.defaultExcelExportParams}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* excel export with formula - end */}

                    {/* <button onClick={this.props.increment}>jdhsjjksdhk</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer logoutSubmit={e => this.logoutLink()} />
      </div>
    );
  }
}
// export default vendor_balance_detail
const mapStateToProps = state => {
  return {
    userAuthdetails: state,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    increment: () => dispatch({ type: 'ADD_LOGGED_USER_DETAILS', payload: 'hiiiiiii' }),
    decrement: () => dispatch({ type: 'DECREMENT' }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(vendor_balance_detail);
